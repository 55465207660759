import { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    IconButton,
    TextField,
    Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { create } from 'zustand';
import { t } from 'i18next';

type HelpDialogStore = {
    open: boolean;
    orderNumbers: string[];
    onSubmit: (userQuery: string) => void;
    close: () => void;
};

const useHelpDialogStore = create<HelpDialogStore>((set) => ({
    open: false,
    orderNumbers: [],
    onSubmit: () => {},
    close: () => set({ open: false }),
}));

export const openHelpDialog = (orderNumbers: string[], onSubmit: (userQuery: string) => void) => {
    useHelpDialogStore.setState({
        open: true,
        orderNumbers: orderNumbers,
        onSubmit,
    });
};

export const HelpDialog = () => {
    const { open, orderNumbers: orderNumbers, onSubmit, close } = useHelpDialogStore();
    const [userQuery, setUserQuery] = useState('');

    const handleSubmit = () => {
        onSubmit(userQuery);
        close();
        setUserQuery('');
    };

    if (!open) return null; // Ensure dialog is not rendered if not open

    return (
        <Dialog open={open} onClose={close} maxWidth='sm' fullWidth>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <DialogTitle>{t('Need Help?')}</DialogTitle>
                <IconButton onClick={close}>
                    <Close />
                </IconButton>
            </div>
            <DialogContent>
                <Typography variant='subtitle1'>{t('Selected order numbers')}</Typography>
                {orderNumbers.map((orderNumber, index) => (
                    <Typography key={index} variant='body2' style={{ wordBreak: 'break-all' }}>
                        {orderNumber}
                    </Typography>
                ))}
                <TextField
                    autoFocus
                    margin='dense'
                    id='query'
                    label={t('Describe your issue or query')}
                    type='text'
                    fullWidth
                    variant='outlined'
                    value={userQuery}
                    onChange={(e) => setUserQuery(e.target.value)}
                    style={{ marginTop: '1em' }}
                />
            </DialogContent>
            <DialogActions>
                <Button color='primary' variant='contained' onClick={close}>
                    {t('Cancel')}
                </Button>
                <Button color='secondary' variant='contained' onClick={handleSubmit}>
                    {t('Submit')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default HelpDialog;
