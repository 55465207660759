import { CircularProgress } from '@mui/material';
import logoAcomba from '../../images/logo_acomba.svg';
import logoQBO from '../../images/qb-logo-horizontal-preferred.svg';
import { useTranslation } from 'react-i18next';
import SoftwareButton from './SoftwareButton';
import { useEffect, useState } from 'react';
import { IntegrationTypes, WebAppConfig } from '@progyconnect/webapp-types';
import { IntegrationContextType, useIntegration } from '../../contexts/IntegrationProvider';
import { useDispatch } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { ActionTypes } from '../../redux/Action';

const WizardSelect = () => {
    const { t } = useTranslation();

    const [waiting, setWaiting] = useState(false);
    const dispatch = useDispatch<Dispatch<Action>>();
    const { apiClient } = useIntegration() as IntegrationContextType;
    const [startTime] = useState<number>(Date.now());
    const [error, setError] = useState<string>();

    useEffect(() => {
        const fetchConfig = async () => {
            if (!waiting) return;

            const config = await apiClient.getConfig();
            if (!config || config.type === undefined) {
                setTimeout(() => {
                    fetchConfig();
                }, 5000);
            } else {
                setWaiting(false);
                dispatch({
                    type: ActionTypes.SET_CONFIG,
                    payload: { config },
                });
            }
        };

        fetchConfig();
    }, [apiClient, startTime, waiting]);

    const selectSoftware = (type: IntegrationTypes) => {
        setWaiting(true);
        apiClient.selectIntegrationType(type);
    };

    return (
        <div>
            <div style={{ opacity: waiting ? 0.5 : 1, pointerEvents: waiting ? 'none' : 'auto' }}>
                <h1>{t('Select your accounting software')}</h1>
                <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                    <SoftwareButton
                        logo={logoQBO}
                        type='Qbo'
                        style={{ height: '150px' }}
                        selectSoftware={selectSoftware}
                    />
                    <SoftwareButton
                        logo={logoAcomba}
                        type='Acomba'
                        style={{ height: '80px', marginTop: '4px' }}
                        selectSoftware={selectSoftware}
                    />
                </div>
            </div>
            {waiting && (
                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <CircularProgress />
                </div>
            )}
        </div>
    );
};

export default WizardSelect;
