import { useLocalization } from '../../contexts/LocalizationContext';

export function LanguageSwitcher() {
    const { toggleLanguage, lang } = useLocalization();

    return (
        <div className='form-row-half link-lang'>
            <div onClick={() => toggleLanguage()}>{lang.code === 'en' ? 'Français' : 'English'}</div>
        </div>
    );
}
