import React, { useState, useRef, useEffect } from 'react';
import { Box } from '@mui/material';
import WizardPoliciesEN from './WizardPoliciesEn';
import WizardPoliciesFR from './WizardPoliciesFr';
import { useLocalization } from '../../contexts/LocalizationContext';

const WizardPolicies = ({ handleContinueClick }: { handleContinueClick?: () => void }) => {
    const { lang } = useLocalization();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100vh',
                p: 2,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        flex: '0 0 auto',
                        marginBottom: 2,
                        overflowY: 'auto',
                        maxHeight: handleContinueClick ? 'calc(100% - 200px)' : '100%', //when in use by wizard, the button is defined and we need to leave some space at the bottom
                        maxWidth: 800,
                        paddingTop: '50px',
                    }}
                >
                    {lang.code === 'en' ? (
                        <WizardPoliciesEN handleContinueClick={handleContinueClick} />
                    ) : (
                        <WizardPoliciesFR handleContinueClick={handleContinueClick} />
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default WizardPolicies;
