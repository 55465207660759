import { Typography, FormControlLabel, Checkbox, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useState } from 'react';

const WizardPoliciesFR = ({ handleContinueClick }: { handleContinueClick?: () => void }) => {
    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(event.target.checked);
    };
    return (
        <>
            <Typography variant='h4'>Politique de confidentialité</Typography>
            <Typography variant='body1'>
                Chez ProgyMédia, nous respectons votre vie privée et nous nous engageons à protéger les informations
                personnelles que vous nous fournissez. Nous ne partageons pas vos informations avec des tiers et
                n'utilisons ces informations que pour assurer le bon fonctionnement et améliorer notre logiciel.
            </Typography>
            <Typography variant='h5' style={{ marginTop: '10px' }}>
                Informations que nous recueillons
            </Typography>
            <Typography variant='body1'>
                Nous recueillons des informations telles que votre nom d'utilisateur, votre adresse e-mail et vos
                préférences de compte lorsque vous vous inscrivez à ProgyLink. Nous pouvons également recueillir des
                informations sur votre utilisation de notre logiciel, telles que les commandes que vous transférez et
                les produits qui y sont associés.
            </Typography>
            <Typography variant='h5' style={{ marginTop: '10px' }}>
                Utilisation des informations
            </Typography>
            <Typography variant='body1'>
                Nous utilisons les informations que nous recueillons pour améliorer notre logiciel et pour vous fournir
                une meilleure expérience utilisateur. Nous ne partageons pas vos informations avec des tiers, sauf si
                nous y sommes contraints par la loi ou si vous nous y autorisez expressément.
            </Typography>
            <Typography variant='h5' style={{ marginTop: '10px' }}>
                Sécurité des informations
            </Typography>
            <Typography variant='body1'>
                Nous prenons des mesures de sécurité appropriées pour protéger les informations que vous nous fournissez
                contre la perte, l'utilisation abusive et l'accès non autorisé. Nous stockons les informations sur des
                serveurs sécurisés et nous utilisons des protocoles de cryptage pour protéger les communications.
            </Typography>
            <Typography variant='h5' style={{ marginTop: '10px' }}>
                Conservation des informations
            </Typography>
            <Typography variant='body1'>
                Nous ne conservons vos informations que pendant la durée nécessaire pour fournir notre service et pour
                se conformer à nos obligations légales. Si vous souhaitez supprimer vos informations de notre système,
                veuillez nous contacter à l'adresse e-mail indiquée ci-dessous.
            </Typography>
            <Typography variant='h5' style={{ marginTop: '10px' }}>
                Contactez-nous
            </Typography>
            <Typography variant='body1'>
                Si vous avez des questions ou des préoccupations concernant notre politique de confidentialité, ou si
                vous souhaitez supprimer vos informations de notre système, veuillez nous contacter à l'adresse e-mail
                suivante : privacy@progymedia.com.
            </Typography>
            <Typography variant='h4' style={{ marginTop: '20px' }}>
                Conditions d'utilisation
            </Typography>
            <Typography variant='h5' style={{ marginTop: '10px' }}>
                Acceptation des conditions d'utilisation
            </Typography>
            <Typography variant='body1'>
                En utilisant l'application ProgyLink (ci-après "l'Application"), vous acceptez les présentes conditions
                d'utilisation ("Conditions"). Si vous n'êtes pas d'accord avec les présentes Conditions, veuillez ne pas
                utiliser l'Application.
            </Typography>
            <Typography variant='h5' style={{ marginTop: '10px' }}>
                Description de l'application
            </Typography>
            <Typography variant='body1'>
                ProgyLink est une application qui permet aux utilisateurs de se connecter avec des logiciels comptable
                et ERP afin de transférer différents documents automatiquement tel que commandes et factures. ProgyLink
                est la propriété de ProgyMédia inc. (ci-après "ProgyMédia").
            </Typography>
            <Typography variant='h5' style={{ marginTop: '10px' }}>
                Utilisation de l'application
            </Typography>
            <Typography variant='body1'>
                L'utilisation de l'Application est soumise aux conditions suivantes :
            </Typography>
            <Typography variant='body1' component='ul' sx={{ m: 2, p: 0 }}>
                <li>
                    Vous ne pouvez pas utiliser l'Application à des fins illégales ou pour causer des dommages à
                    d'autres utilisateurs ou à l'Application elle-même.
                </li>
                <li> Vous êtes responsable de toute activité qui se produit sous votre compte.</li>
                <li>
                    L'Application et tout son contenu, y compris, mais sans s'y limiter les logos, les textes, les
                    images, les vidéos, les fichiers, les logiciels et les codes, sont protégés par les lois sur la
                    propriété intellectuelle. Vous ne pouvez pas utiliser, reproduire, distribuer ou modifier le contenu
                    de l'Application sans l'autorisation écrite préalable de ProgyMédia.
                </li>
            </Typography>
            <Typography variant='h5' style={{ marginTop: '10px' }}>
                Confidentialité
            </Typography>
            <Typography variant='body1'>
                ProgyMédia s'engage à respecter votre vie privée et à protéger les informations personnelles que vous
                fournissez lors de l'utilisation de l'Application. Veuillez consulter notre politique de confidentialité
                pour plus d'informations sur la manière dont nous recueillons, utilisons et protégeons vos données.
            </Typography>
            <Typography variant='h5' style={{ marginTop: '10px' }}>
                Modifications des conditions d'utilisation
            </Typography>
            <Typography variant='body1'>
                ProgyMédia se réserve le droit de modifier ces conditions d'utilisation à tout moment. Toute
                modification sera publiée sur l'Application et vous serez informé(e) de ces modifications par e-mail ou
                tout autre moyen de communication approprié. Votre utilisation continue de l'Application après la
                publication des modifications constitue votre acceptation de ces modifications.
            </Typography>
            <Typography variant='h5' style={{ marginTop: '10px' }}>
                Résiliation
            </Typography>
            <Typography variant='body1'>
                ProgyMédia peut résilier votre droit d'accès à l'Application à tout moment si vous violez ces conditions
                d'utilisation.
            </Typography>
            <Typography variant='h5' style={{ marginTop: '10px' }}>
                Limitation de responsabilité
            </Typography>
            <Typography variant='body1' style={{ marginBottom: '15px' }}>
                ProgyMédia ne peut en aucun cas être tenu responsable de tout dommage direct ou indirect résultant de
                l'utilisation ou de l'impossibilité d'utiliser l'Application, y compris les pertes de données, les
                interruptions de service, les erreurs, les omissions, les virus, les retards ou les perturbations dans
                les opérations. `;
            </Typography>
            {handleContinueClick && (
                <>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                                icon={<CheckCircleOutlineIcon />}
                                checkedIcon={<CheckCircleOutlineIcon sx={{ color: 'primary.main' }} />}
                                color='primary'
                            />
                        }
                        label='J’accepte les conditions d’utilisation et la politique de confidentialité.'
                    />
                    <Button variant='contained' color='primary' onClick={handleContinueClick} disabled={!isChecked}>
                        Continue
                    </Button>
                </>
            )}
        </>
    );
};

export default WizardPoliciesFR;
