import { ReactNode, createContext, useContext, useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { useUnvalidatedToken } from './UnvalidatedTokenProvider';
import { useAuthentication } from './AuthenticationProvider';

const IdentityContext = createContext<{
    token: string | undefined;
    isLoading: boolean;
}>({
    token: undefined,
    isLoading: true,
});

export function useIdentity() {
    return useContext(IdentityContext);
}

export default function IdentityProvider({ children }: { children: ReactNode }) {
    const {
        isAuthenticated: isAuth0Authenticated,
        isLoading: isAuth0Loading,
        getAccessTokenSilently,
        getIdTokenClaims,
        loginWithRedirect,
    } = useAuth0();

    const { authConfig } = useAuthentication();

    const { unvalidatedToken, isLoading: isUnvalidatedTokenLoading, storeLocalToken } = useUnvalidatedToken();

    const [identityToken, setIdentityToken] = useState<string | undefined>(undefined);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!authConfig) return;

        if (import.meta.env.VITE_INTEGRATION_ID) {
            //invalid test token that only works localy to set the username to Progymedia when doing local development
            setIdentityToken(
                'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuaWNrbmFtZSI6InByb2d5bWVkaWEifQ.w57eMQj4pW8RTGyhjLj70UaB9LD0Jj5FLDcMyv6uKVY',
            );
        } else if (authConfig?.type === 'auth0') {
            if (!isAuth0Loading)
                if (isAuth0Authenticated) {
                    (async () => {
                        await getAccessTokenSilently({});

                        const tokenClaim = await getIdTokenClaims();

                        if (tokenClaim) setIdentityToken(tokenClaim.__raw);
                        setIsLoading(false);
                    })();
                } else {
                    loginWithRedirect();
                }
        } else if (authConfig?.type === 'query-token') {
            console.log('isUnvalidatedTokenLoading', isUnvalidatedTokenLoading);
            console.log('unvalidatedToken', unvalidatedToken);
            if (!isUnvalidatedTokenLoading) {
                if (unvalidatedToken) {
                    (async () => {
                        const response = await fetch(`/api/v1/validateToken`, {
                            method: 'GET',
                            headers: {
                                Authorization: 'Bearer ' + unvalidatedToken!,
                            },
                            cache: 'no-store',
                        });

                        console.log(response);

                        if (response.status === 200) {
                            //if the token is valid, store it and set it as the identity token
                            setIdentityToken(unvalidatedToken);
                            storeLocalToken(unvalidatedToken);
                        } else if (authConfig.redirectUrl) {
                            //if the token is invalid and a redirect url is provided, redirect to that url
                            window.location.href = authConfig.redirectUrl;
                        }

                        setIsLoading(false);
                    })();
                } else if (authConfig.redirectUrl) window.location.href = authConfig.redirectUrl;
                else throw new Error('No token found in query string and no redirect url provided in auth config');
            }
        }
    }, [
        isAuth0Loading,
        isAuth0Authenticated,
        getAccessTokenSilently,
        getIdTokenClaims,
        isUnvalidatedTokenLoading,
        unvalidatedToken,
        authConfig,
    ]);

    if (!isAuth0Loading && !isUnvalidatedTokenLoading && !identityToken) return <div>Failed to authenticate</div>;

    return (
        <IdentityContext.Provider
            value={{
                token: identityToken,
                isLoading: !import.meta.env.VITE_INTEGRATION_ID && (isAuth0Loading || isUnvalidatedTokenLoading),
            }}
        >
            {children}
        </IdentityContext.Provider>
    );
}
