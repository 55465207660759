import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    Switch,
    TextField,
} from '@mui/material';
import { IntegrationContextType, useIntegration } from '../contexts/IntegrationProvider';
import { useEffect, useState } from 'react';

import { Close } from '@mui/icons-material';
import { SupplierIntegrationConfig } from '@progyconnect/webapp-types';
import { create } from 'zustand';
import { t } from 'i18next';

type ConfigDialogStore = {
    open: boolean;
    config: SupplierIntegrationConfig;
    onSubmit: (config: SupplierIntegrationConfig) => void;
    close: () => void;
};

const useConfigDialogStore = create<ConfigDialogStore>((set) => ({
    open: false,
    config: {} as SupplierIntegrationConfig,
    onSubmit: () => {},
    close: () => set({ open: false }),
}));

export const openConfigDialog = (
    onSubmit: (config: SupplierIntegrationConfig) => void,
    retrievedConfig: SupplierIntegrationConfig | undefined,
) => {
    useConfigDialogStore.setState({
        open: true,
        config: retrievedConfig || ({} as SupplierIntegrationConfig),
        onSubmit,
    });
};

export const ConfigDialog = () => {
    const { open, config, onSubmit, close } = useConfigDialogStore();
    const [localConfig, setLocalConfig] = useState<SupplierIntegrationConfig>(config);
    const { apiClient } = useIntegration() as IntegrationContextType;

    // Cette partie récupère la configuration actuelle
    useEffect(() => {
        if (open) {
            const fetchConfig = async () => {
                try {
                    const fetchedConfig = await apiClient.getConfig();
                    console.log('fetchedConfig:', JSON.stringify(fetchedConfig));
                    setLocalConfig(fetchedConfig as SupplierIntegrationConfig);
                } catch (error) {
                    console.error('Failed to get config:', error);
                }
            };
            if (open) fetchConfig();
        }
    }, [open, apiClient]);

    useEffect(() => {
        setLocalConfig(config);
    }, [config]);

    const handleInputChange = (field: string, value: any) => {
        console.log('field:', field, 'value:', value);
        let updatedConfig;
        if (field === 'automode.order') {
            updatedConfig = {
                ...localConfig,
                orderWorkflowConfigs: {
                    ...localConfig.orderWorkflowConfigs,
                    autoMode: {
                        ...localConfig.orderWorkflowConfigs.autoMode,
                        order: value,
                    },
                },
            };
        } else {
            updatedConfig = {
                ...localConfig,
                orderWorkflowConfigs: {
                    ...localConfig.orderWorkflowConfigs,
                    [field]: value,
                },
            };
        }
        console.log('updatedConfig:', JSON.stringify(updatedConfig));
        setLocalConfig(updatedConfig);
    };

    const handleSubmit = () => {
        onSubmit(localConfig);
        close();
    };

    // Génération des champs pour `config`
    const renderTextField = (key: string, value: unknown) => (
        <TextField
            key={key}
            autoFocus
            margin='dense'
            id={key}
            label={t(key || 'unknown')}
            type='text'
            fullWidth
            variant='outlined'
            value={value as string}
            onChange={(e) => handleInputChange(key, e.target.value)}
            style={{ marginBottom: '1em' }}
        />
    );

    const renderSwitch = (key: string, checked: boolean) => (
        <FormControlLabel
            control={<Switch checked={checked} onChange={(e) => handleInputChange(key, e.target.checked)} />}
            label={t('Automatic mode for Orders')}
            key={key}
            style={{ marginBottom: '1em' }}
        />
    );

    if (!open) return null;

    return (
        <Dialog open={open} onClose={close} maxWidth='sm' fullWidth>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <DialogTitle>Configuration</DialogTitle>
                <IconButton onClick={close} style={{ position: 'absolute', right: 8, top: 8 }}>
                    <Close />
                </IconButton>
            </div>
            <DialogContent>
                <>
                    {renderSwitch('automode.order', localConfig.orderWorkflowConfigs?.autoMode?.order || false)}
                    {renderTextField('orderNumberPrefix', localConfig.orderWorkflowConfigs?.orderNumberPrefix || '')}
                </>
            </DialogContent>
            <DialogActions>
                <Button color='primary' variant='contained' onClick={close}>
                    {t('Cancel')}
                </Button>
                <Button color='secondary' variant='contained' onClick={handleSubmit}>
                    {t('Submit')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfigDialog;
