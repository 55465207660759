import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { CircularProgress } from '@mui/material';
import { AuthenticationProviderConfiguration } from '@progyconnect/webapp-types';
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiClient } from '../ApiClient';
import UnvalidatedTokenProvider from './UnvalidatedTokenProvider';

const AuthenticationContext = createContext<{
    authConfig: AuthenticationProviderConfiguration | undefined;
    isLoading: boolean;
}>({
    authConfig: undefined,
    isLoading: true,
});

export function useAuthentication() {
    return useContext(AuthenticationContext);
}

export default function AuthenticationProvider({ children }: { children: ReactNode }) {
    const [authConfig, setAuthConfig] = useState<AuthenticationProviderConfiguration>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    useEffect(() => {
        (async () => {
            setAuthConfig(await new ApiClient().getAuthenticationProviderConfiguration());
            setIsLoading(false);
        })();
    }, []);

    const navigate = useNavigate();

    const onRedirectCallback = (appState?: AppState) => {
        navigate((appState && appState.returnTo) || window.location.pathname);
    };

    if (isLoading || !authConfig)
        return (
            <div
                style={{
                    height: '100vh',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <CircularProgress />
            </div>
        );

    if (import.meta.env.VITE_INTEGRATION_ID)
        return (
            <AuthenticationContext.Provider
                value={{
                    authConfig,
                    isLoading,
                }}
            >
                {children}
            </AuthenticationContext.Provider>
        );
    else if (authConfig.type === 'auth0')
        return (
            <AuthenticationContext.Provider
                value={{
                    authConfig,
                    isLoading,
                }}
            >
                <Auth0Provider
                    onRedirectCallback={onRedirectCallback}
                    clientId={authConfig.clientId}
                    domain={authConfig.domain}
                    redirectUri={window.location.origin.replace('127.0.0.1', 'localhost')}
                    returnTo={window.location.origin.replace('127.0.0.1', 'localhost')}
                >
                    {children}
                </Auth0Provider>
            </AuthenticationContext.Provider>
        );
    else if (authConfig.type === 'query-token')
        return (
            <AuthenticationContext.Provider
                value={{
                    authConfig,
                    isLoading,
                }}
            >
                <UnvalidatedTokenProvider>{children}</UnvalidatedTokenProvider>
            </AuthenticationContext.Provider>
        );
    else return <div>Invalid authentication provider configuration</div>;
}
