import {
    Avatar,
    Badge,
    Box,
    Collapse,
    Divider,
    Drawer,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    capitalize,
    styled,
    useTheme,
} from '@mui/material';
import ConfigDialog, { openConfigDialog } from './ConfigDialog';
import { IntegrationContextType, useIntegration } from '../contexts/IntegrationProvider';
import type { Mapping, SupplierIntegrationConfig, WebAppConfig, WebappEntryType } from '@progyconnect/webapp-types';
import { useEffect, useState } from 'react';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Icons from './Icons';
import { IntegrationsCombo } from './IntegrationCombo';
import LanguageIcon from '@mui/icons-material/Language';
import { LanguageSwitcher } from './LanguageSwitcher';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import { State } from '../redux/state';
import { entryTypes } from '../entryTypes';
import { keyframes } from '@mui/system';
import progyLinkLogo from '../images/progylink.svg';
import { t } from 'i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';

const NavigationDrawer = ({
    config,
    supplierConfig: supplierConfig,
    selection,
    setSelection,
    setConnectionInfoVisible,
    setShowPolicies,
}: {
    config: WebAppConfig;
    supplierConfig: SupplierIntegrationConfig | undefined;
    selection: WebappEntryType | undefined;
    setSelection: (selection: WebappEntryType) => void;
    setConnectionInfoVisible: (visible: boolean) => void;
    setShowPolicies: (show: boolean) => void;
}) => {
    const drawerWidth = 240;

    const { logout, user } = useAuth0();

    const handleAvatarMenuClick = () => {
        setAvatarMenuOpen(!avatarMenuOpen);
    };
    const [avatarMenuOpen, setAvatarMenuOpen] = useState(false);
    const { integrations } = useIntegration() as IntegrationContextType;
    const mappings = useSelector<State, { [key: string]: Mapping[] } | undefined>((state) => state.mappings);

    const [isAgentConnected, setIsAgentConnected] = useState(false);

    const theme = useTheme();

    const blink = keyframes`
    0% {opacity: 0;}
    10% {opacity: 1;}
    90% {opacity: 1;}
    100% {opacity: 0;}
    `;

    const ConnectionDot = styled(Box)(({ theme }) => ({
        width: 10,
        height: 10,
        borderRadius: '50%',
        animation: `${blink} 10s infinite`,
        backgroundColor: theme.palette.error.main, // default to red
        '&.AgentIsConnected': {
            backgroundColor: theme.palette.success.main, // green when connected
        },
    }));

    const { apiClient } = useIntegration() as IntegrationContextType;

    const handleConfig = () => {
        openConfigDialog((config: SupplierIntegrationConfig) => {
            if (config) {
                let payload: any = {
                    orderNumberPrefix: config.orderWorkflowConfigs.orderNumberPrefix,
                };
                if (config.orderWorkflowConfigs?.autoMode) {
                    payload.autoMode = config.orderWorkflowConfigs.autoMode;
                }
                console.log('payload:', payload);
                apiClient.saveConfig(payload);
            }
        }, supplierConfig);
    };

    useEffect(() => {
        const checkAgentStatus = async () => {
            if (!apiClient) return;
            if (config.type !== 'Qbo') {
                const status = await apiClient.getAgentStatus(config);
                setIsAgentConnected(status);
            }
        };

        // Check status immediately
        checkAgentStatus();

        // Then check every minute
        const intervalId = setInterval(checkAgentStatus, 5 * 1000 * 60);

        // Clean up interval on unmount
        return () => clearInterval(intervalId);
    }, [apiClient]);

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                },
            }}
            variant='permanent'
            anchor='left'
        >
            <List style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                <div>
                    <ListItemIcon>
                        <div className='image-container' style={{ padding: '10px' }}>
                            <img src={progyLinkLogo} alt='Progy Link' width='220'></img>
                            <span style={{ fontSize: '12px' }}>
                                développé par{' '}
                                <a href='https://www.progymedia.com' target='_blank' rel='noreferrer'>
                                    progymedia.com
                                </a>
                            </span>
                        </div>
                    </ListItemIcon>

                    <Divider />

                    <ListItem key={'avatar'} onClick={handleAvatarMenuClick} disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <Avatar alt={user?.name} src={user?.picture} />
                            </ListItemIcon>
                            <ListItemText primary={user?.nickname} />
                            {avatarMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </ListItemButton>
                    </ListItem>

                    <Collapse in={avatarMenuOpen} timeout='auto' unmountOnExit>
                        <List component='div' disablePadding dense>
                            <ListItem>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <LanguageIcon />
                                    </ListItemIcon>
                                    <LanguageSwitcher />
                                </ListItemButton>
                            </ListItem>
                            <ListItem>
                                <ListItemButton
                                    onClick={() =>
                                        logout({
                                            returnTo: window.location.href,
                                        })
                                    }
                                >
                                    <ListItemIcon>
                                        <LogoutIcon />
                                    </ListItemIcon>
                                    {t('Disconnect')}
                                </ListItemButton>
                            </ListItem>
                            <ListItem>
                                {config.type === 'Acomba' && (
                                    <ListItemButton
                                        onClick={() => {
                                            if (config.type === 'Acomba') setConnectionInfoVisible(true);
                                        }}
                                    >
                                        <ListItemIcon>
                                            <CloudDownloadIcon />
                                        </ListItemIcon>

                                        {t('Download agent')}
                                    </ListItemButton>
                                )}
                            </ListItem>

                            <ListItem>
                                <ListItemButton
                                    onClick={() => {
                                        handleConfig();
                                    }}
                                >
                                    <ListItemIcon>
                                        <SettingsIcon />
                                    </ListItemIcon>

                                    {t('Configuration')}
                                </ListItemButton>
                            </ListItem>

                            {integrations && integrations.length > 1 && (
                                <ListItem>
                                    <IntegrationsCombo />
                                </ListItem>
                            )}
                        </List>
                    </Collapse>

                    <Divider />

                    {entryTypes[config!.customer].map((item) => {
                        function getItemCount(key: string | undefined) {
                            if (!key || !mappings?.[key]) return undefined;

                            return mappings[key].filter((m) => m.state !== 'mapped').length;
                        }

                        return (
                            <ListItem key={item.class} disablePadding>
                                <ListItemButton
                                    selected={selection === item}
                                    onClick={() => {
                                        setSelection(item);
                                    }}
                                >
                                    <ListItemIcon>
                                        <Icons icon={item.icon} />
                                    </ListItemIcon>
                                    <ListItemText primary={t(item.label)} />
                                    <Badge badgeContent={getItemCount(item.class)} color='info' />
                                </ListItemButton>
                            </ListItem>
                        );
                    })}

                    <ConfigDialog />
                </div>

                <div style={{ alignSelf: 'flex-end' }}>
                    {config.type !== 'Qbo' && (
                        <Grid
                            container
                            justifyContent='space-between'
                            alignItems='center'
                            sx={{ padding: theme.spacing(1), marginBottom: theme.spacing(2) }}
                        >
                            <Typography variant='body1' sx={{ paddingLeft: theme.spacing(1) }}>
                                {isAgentConnected ? t('Agent is connected') : t('Agent is not connected')}
                            </Typography>
                            <ConnectionDot
                                className={isAgentConnected ? 'AgentIsConnected' : ''}
                                sx={{ paddingRight: theme.spacing(1), marginRight: theme.spacing(1) }}
                            />
                        </Grid>
                    )}
                    <ListItem key='policies' disablePadding>
                        <ListItemButton
                            onClick={() => {
                                setShowPolicies(true);
                            }}
                        >
                            <ListItemIcon>
                                <Icons icon={'Gavel'} />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography sx={{ fontSize: '10px' }}>
                                        {t('Privacy Policy and Terms of Use')}
                                    </Typography>
                                }
                            />
                        </ListItemButton>
                    </ListItem>
                </div>
            </List>
        </Drawer>
    );
};

export default NavigationDrawer;
