import { Action, ActionTypes } from './redux/Action';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { CircularProgress, Collapse, CssBaseline } from '@mui/material';
import { IntegrationContextType, useIntegration } from './contexts/IntegrationProvider';
import type {
    MaintenanceType,
    SupplierIntegrationConfig,
    WebAppConfig,
    WebappEntryType,
} from '@progyconnect/webapp-types';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Changelog from './changelogs/changelog';
import { ConfigDialog } from './components/ConfigDialog';
import { ConnectionInfo } from './components/ConnectionInfo';
import { Dispatch } from 'redux';
import MaintenancePage from './components/Maintenance';
import Mappings from './layout/Mappings';
import NavigationDrawer from './components/NavigationDrawer';
import Orders from './layout/Invoicings';
import { State } from './redux/state';
import Wizard from './layout/Wizard';
import WizardPolicies from './components/configWizard/WizardPolicies';
import { entryTypes } from './entryTypes';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useLocalization } from './contexts/LocalizationContext';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function App() {
    const { apiClient } = useIntegration() as IntegrationContextType;
    const { integrationId } = useIntegration() as IntegrationContextType;

    const [showMaintenanceMessage, setShowMaintenanceMessage] = useState(true);

    const [selection, setSelection] = useState<WebappEntryType>();

    const config = useSelector<State, WebAppConfig | undefined>((state) => state.config);
    const supplierConfig = useSelector<State, SupplierIntegrationConfig | undefined>((state) => state.supplierConfig);
    const maintenanceInfo = useSelector<State, MaintenanceType | undefined>((state) => state.maintenance);

    const { lang } = useLocalization();
    const dispatch = useDispatch<Dispatch<Action>>();

    const [showMissingsOnly, setShowMissingsOnly] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [connectionInfoVisible, setConnectionInfoVisible] = useState(false);

    const [showPolicyWizard, setShowPolicyWizard] = useState(false);
    const { t } = useTranslation();

    const [isAgentVersionUpToDate, setIsAgentVersionUpToDate] = useState(true);

    useEffect(() => {
        if (!apiClient || !integrationId) return;

        (async () => {
            const configPromise = apiClient.getConfig();
            const maintenantInfoPromise = apiClient.getMaintenanceInfo();
            const [config, maintenantInfo] = await Promise.all([configPromise, maintenantInfoPromise]);
            const isConnected = await apiClient.isConnected();
            const isUpToDate = await apiClient.checkAgentVersion(config);
            if (!isUpToDate) {
                setIsAgentVersionUpToDate(false);
            }

            setIsLoading(false);

            dispatch({
                type: ActionTypes.SET_STATE_NO_MAPPINGS,
                payload: {
                    config: config,
                    maintenance: maintenantInfo,
                    isConnected: isConnected,
                },
            });

            const maintenanceInterval = setInterval(async () => {
                const maintenantInfo = await apiClient.getMaintenanceInfo();
                dispatch({
                    type: ActionTypes.SET_MAINTENANCE,
                    payload: {
                        maintenance: maintenantInfo,
                    },
                });
            }, 1000 * 60 * 5);

            return () => {
                clearInterval(maintenanceInterval);
            };
        })();
    }, [apiClient, integrationId]);

    useEffect(() => {
        if (config?.orderWorkflowConfigs?.status !== 'active') return;

        (async () => {
            entryTypes[config!.customer].map((entryType) => {
                if (entryType.type === 'invoicing') return;

                return apiClient.getMappings(entryType, lang.code === 'en' ? 'EN' : 'FR').then((mappings) => {
                    dispatch({
                        type: ActionTypes.SET_MAPPINGS_BY_TYPE,
                        payload: {
                            class: entryType.class,
                            mappings: mappings ?? [],
                        },
                    });
                });
            });

            const valueTypes = entryTypes[config!.customer]
                .filter((m) => m.type === 'mapping')
                .map((m) => m.type === 'mapping' && m.valueClass)
                .filter((value, index, self) => {
                    return value && self.indexOf(value) === index;
                }) as string[];

            valueTypes.map((valueType) =>
                apiClient.getMappingValues(valueType).then((values) =>
                    dispatch({
                        type: ActionTypes.UPDATE_MAPPING_VALUES,
                        payload: {
                            type: valueType,
                            mappingValues: values ?? [],
                        },
                    }),
                ),
            );
        })();

        if (config && !selection) setSelection(entryTypes[config!.customer][0]);
    }, [apiClient, config]);

    if (isLoading)
        return (
            <div
                style={{
                    height: '100vh',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <CircularProgress />
            </div>
        );

    if (maintenanceInfo!.enabled)
        return (
            <MaintenancePage to={maintenanceInfo!.timestamp_to ? new Date(maintenanceInfo!.timestamp_to) : undefined} />
        );

    if (!config || config.orderWorkflowConfigs?.status !== 'active') return <Wizard />;

    const SelectedEntry: React.FC = () => {
        {
            if (selection?.type === 'invoicing') return <Orders entryType={selection} />;
            if (selection?.type === 'mapping')
                return (
                    <Mappings
                        type={selection}
                        showMissingsOnly={showMissingsOnly}
                        setShowMissingsOnly={(showMissingsOnly: boolean) => setShowMissingsOnly(showMissingsOnly)}
                    />
                );
            return <></>;
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <NavigationDrawer
                config={config}
                supplierConfig={supplierConfig}
                selection={selection}
                setSelection={(selection) => setSelection(selection)}
                setConnectionInfoVisible={(visible) => setConnectionInfoVisible(visible)}
                setShowPolicies={(show) => setShowPolicyWizard(show)}
            />

            <Box component='main' sx={{ flexGrow: 1, bgcolor: 'background.default', p: 1 }}>
                {maintenanceInfo!.message && (
                    <Collapse in={showMaintenanceMessage}>
                        <Alert
                            severity='info'
                            onClose={() => {
                                setShowMaintenanceMessage(false);
                            }}
                        >
                            {maintenanceInfo!.message}
                        </Alert>
                    </Collapse>
                )}
                <Changelog />
                {connectionInfoVisible && (
                    <ConnectionInfo open={connectionInfoVisible} setOpen={setConnectionInfoVisible} />
                )}
                <SelectedEntry />
                <Dialog open={!isAgentVersionUpToDate} onClose={() => setIsAgentVersionUpToDate(true)}>
                    <DialogTitle>{'Notification'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t("Your agent's version is not up to date. Please update it.")}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsAgentVersionUpToDate(true)} color='primary'>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                ;
            </Box>
            <Box>
                <Dialog open={showPolicyWizard} onClose={() => setShowPolicyWizard(false)} fullWidth maxWidth='lg'>
                    <DialogTitle>
                        <h4>{t('Privacy Policy and Terms of Use')}</h4>
                    </DialogTitle>
                    <DialogContent>
                        <WizardPolicies />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained'
                            size='small'
                            onClick={() => {
                                setShowPolicyWizard(false);
                            }}
                        >
                            {t('Close')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
}

export default App;
