import Button from '@mui/material/Button';
import { useLocalization } from '../../contexts/LocalizationContext';

export function LanguageSwitcherButton() {
    const { toggleLanguage, nextLanguageCode } = useLocalization();

    return (
        <div className='form-row-half link-lang'>
            <Button variant='contained' className=' right toggle-button filled-toggle' onClick={() => toggleLanguage()}>
                {nextLanguageCode()}
            </Button>
        </div>
    );
}
