import { IntegrationContextType, useIntegration } from '../../contexts/IntegrationProvider';

import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';
import qboConnectButton from '../../images/C2QB_green_btn_med_default.svg';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryArg } from '../hooks/useQueryArg';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../redux/state';
import { Action, ActionTypes } from '../../redux/Action';
import { Dispatch } from 'redux';

export default function ConnectToQuickbooks() {
    const { apiClient } = useIntegration() as IntegrationContextType;
    const { t } = useTranslation();
    const [waiting, setWaiting] = useState(false);
    const [returnFromQBOQueryArg, setReturnFromQBOQueryArg] = useQueryArg('returnFromQBO');
    const [returnFromQBO, setReturnFromQBO] = useState(false);
    const isConnected = useSelector<State, boolean | undefined>((state) => state.isConnected);
    const [error, setError] = useState<string>();
    const [startTime] = useState<number>(Date.now());
    const dispatch = useDispatch<Dispatch<Action>>();

    useEffect(() => {
        if (returnFromQBOQueryArg === 'true') {
            setReturnFromQBO(true);
            setReturnFromQBOQueryArg(undefined);
        }
    }, [returnFromQBOQueryArg]);

    useEffect(() => {
        if (isConnected) return; //already connected, no need to wait for connection

        const refetchIsConnected = async () => {
            const isConnected = await apiClient.isConnected();
            if (!isConnected) {
                if (Date.now() - startTime! >= 1 * 60 * 10000) {
                    setError('Timeout while waiting for connection.');
                } else {
                    setTimeout(() => {
                        refetchIsConnected();
                    }, 5000);
                }
            } else {
                dispatch({
                    type: ActionTypes.UPDATE_IS_CONNECTED,
                    payload: {
                        isConnected: true,
                    },
                });
            }
        };

        refetchIsConnected();
    }, [apiClient, startTime]);

    if (error)
        return (
            <div
                style={{
                    height: '100vh',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <p>Error: {error}</p>
            </div>
        );

    return (
        <div
            style={{
                height: '100vh',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {isConnected === undefined ? (
                <CircularProgress />
            ) : !isConnected ? (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: '30px' }}>
                    <Button
                        variant='text'
                        size='small'
                        disabled={waiting || returnFromQBO === true}
                        onClick={() => {
                            setWaiting(true);
                            apiClient
                                .getQBOLoginUrl(window.location.href + '?returnFromQBO=true')
                                .then((url: string) => {
                                    if (url) window.location.href = url;
                                });
                        }}
                        style={{ marginBottom: '20px' }}
                    >
                        <img alt='qbo connect button' src={qboConnectButton} />
                    </Button>
                    {(waiting || returnFromQBO === true) && <CircularProgress />}
                </div>
            ) : (
                <>{t('Connection successfull!')}</>
            )}
        </div>
    );
}
