import { Button, Box, Typography } from '@mui/material';
import { WebAppConfig } from '@progyconnect/webapp-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import pivohub from '../../images/pivohub.svg';
import restock from '../../images/restock.svg';
import progylink from '../../images/progylink.svg';
import { State } from '../../redux/state';

const WizardWelcome = ({ handleContinueClick }: { handleContinueClick: () => void }) => {
    const { t } = useTranslation();
    const config = useSelector<State, WebAppConfig | undefined>((state) => state.config);

    return (
        <Box
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', marginTop: '100px' }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                {config?.customer === 'PivoHub' ? (
                    <>
                        <img src={pivohub} alt='PivoHub Logo' />
                        <Typography variant='h4' sx={{ mx: 2 }}>
                            &rarr;
                        </Typography>
                        <img height='40px' src={progylink} alt='ProgyLink Logo' />
                    </>
                ) : config?.customer === 'Restock' ? (
                    <>
                        <img height='44px' src={restock} alt='Restock Logo' />
                        <Typography variant='h4' sx={{ mx: 2 }}>
                            &rarr;
                        </Typography>
                        <img height='35px' src={progylink} alt='ProgyLink Logo' />
                    </>
                ) : (
                    <> </>
                )}
            </Box>
            <Typography variant='h4' sx={{ mt: 4, mb: 2 }}>
                {t('Welcome to the ProgyLink configurator.')}
            </Typography>
            <Typography variant='subtitle1' sx={{ mb: 4 }}>
                {t(
                    'You will be guided through the configuration of your application. This will connect your {{customer}} account to your accounting software.',
                    { customer: config?.customer },
                )}
            </Typography>
            <Button variant='contained' color='primary' onClick={handleContinueClick}>
                {t('Begin')}
            </Button>
        </Box>
    );
};

export default WizardWelcome;
