import { createContext, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { useLocalStorage } from 'usehooks-ts';

import fr_ from '../i18n/fr.json';

const languages = {
    fr: {
        translation: {
            ...fr_,
        },
    },
};

i18n.use(initReactI18next).init({
    resources: languages,
    lng: 'fr',
});

type LocalizationContextType = {
    lang: { code: 'en' | 'fr'; language: 'English' | 'Français' };
    toggleLanguage: () => void;
    nextLanguageCode: () => string;
    nextLanguage: () => string;
};

const LocalizationContext = createContext<LocalizationContextType>({
    lang: { code: 'en', language: 'English' },
    toggleLanguage: () => null,
    nextLanguageCode: () => '',
    nextLanguage: () => '',
});

export function useLocalization() {
    return useContext(LocalizationContext);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function LocalizationProvider({ children }: any) {
    const { i18n } = useTranslation('layout', { useSuspense: false });

    function getLangObj(code: string) {
        const lang: LocalizationContextType['lang'] =
            code === 'en'
                ? {
                      code: 'fr',
                      language: 'Français',
                  }
                : {
                      code: 'en',
                      language: 'English',
                  };

        return lang;
    }

    const [lang, setLang] = useLocalStorage('progylink-language', getLangObj('fr'));

    function toggleLanguage() {
        setLang(getLangObj(lang.code));
    }

    useEffect(() => {
        if (i18n) i18n.changeLanguage(lang.code);
    }, [lang, i18n]);

    function nextLanguageCode() {
        return lang.code === 'en' ? 'fr' : 'en';
    }

    function nextLanguage() {
        return lang.code === 'en' ? 'Français' : 'English';
    }

    return (
        <LocalizationContext.Provider value={{ lang, toggleLanguage, nextLanguageCode, nextLanguage }}>
            {children}
        </LocalizationContext.Provider>
    );
}
