import ConfigureAcomba from './ConfigureAcomba';
import ConnectToQuickbooks from '../../components/ConnectToQuickbooks/ConnectToQuickbooks';
import type { IntegrationTypes } from '@progyconnect/webapp-types';
import { useSelector } from 'react-redux';
import { State } from '../../redux/state';

const WizardConfigure = () => {
    const integrationType = useSelector<State, IntegrationTypes | undefined>((state) => state.config?.type);

    if (integrationType === 'Qbo') return <ConnectToQuickbooks />;
    if (integrationType === 'Acomba') return <ConfigureAcomba waitForConnection={true} />;

    return <>Unsupported integration type</>;
};

export default WizardConfigure;
