import { CSSProperties, useEffect, useRef, useState } from 'react';

import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    createFilterOptions,
    IconButton,
    Paper,
    Snackbar,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';

import { useTranslation } from 'react-i18next';
import { IntegrationContextType, useIntegration } from '../contexts/IntegrationProvider';

import { Mapping, MappingValue, WebAppConfig, WebappEntryType } from '@progyconnect/webapp-types';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../redux/state';
import { Dispatch } from 'redux';
import { Action, ActionTypes } from '../redux/Action';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { confirmDialog } from '../components/ConfirmDialog';
import BulkActionsList from '../components/BulkActionsList';

export default function Mappings({
    type,
    showMissingsOnly,
    setShowMissingsOnly,
}: {
    type: WebappEntryType;
    showMissingsOnly: boolean;
    setShowMissingsOnly: (showMissingsOnly: boolean) => void;
}) {
    const { t } = useTranslation();
    const mappings = useSelector<State, { [key: string]: Mapping[] } | undefined>((state) => state.mappings);
    const mappingValues = useSelector<State, { [key: string]: MappingValue[] } | undefined>(
        (state) => state.mappingValues,
    );
    const config = useSelector<State, WebAppConfig | undefined>((state) => state.config);

    const dispatch = useDispatch<Dispatch<Action>>();
    const { apiClient } = useIntegration() as IntegrationContextType;

    const [openSnackbarMessage, setOpenSnackbarMessage] = useState<string | undefined>();

    const [updatingValues, setUpdatingValues] = useState<boolean>(false);
    const [creatingCustomer, setCreatingCustomer] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState('');

    const valueType = type.type === 'mapping' ? type.valueClass : undefined;
    const mappingType = type.type === 'mapping' ? type.class : undefined;

    if (!mappings || !mappingValues || (updatingValues && !creatingCustomer) || !valueType) {
        return (
            <div
                style={{
                    height: '100vh',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <CircularProgress />
            </div>
        );
    }

    const handleUpdateMappingValues = async () => {
        if (!valueType) return;

        setUpdatingValues(true);

        const newValues = await apiClient.getMappingValues(valueType, true);

        dispatch({
            type: ActionTypes.UPDATE_MAPPING_VALUES,
            payload: {
                type: valueType,
                mappingValues: JSON.parse(JSON.stringify(newValues)),
            },
        });
        setUpdatingValues(false);
    };

    const handlePivoHubUpdateList = async () => {
        //Get all pivoEntityId from mappings
        const pivoEntityIds: string[] = [];
        const mappings = getEffectiveMappings();
        mappings.forEach((mapping) => {
            if (mapping.state === 'mapped' || mapping.state === 'pending-mapping') {
                pivoEntityIds.push(mapping.entityId);
            }
        });

        await apiClient.reloadDetails(mappingType!, pivoEntityIds);
    };

    const handleCreateCustomer = async (mapping: Mapping) => {
        setCreatingCustomer(true);

        try {
            await apiClient.createCustomer(mapping);

            await Promise.all([
                handlePivoHubUpdateList(),
                handleUpdateMappingValues(),
                new Promise((resolve) => setTimeout(resolve, 2000)),
            ]);

            setOpenSnackbarMessage(t('Customer created successfully') ?? '');
        } catch (error) {
            console.error(error);
            setOpenSnackbarMessage(t('Error creating customer') ?? '');
        } finally {
            setCreatingCustomer(false);
        }
    };


    const prepareOptions = () => {
        let options = mappingValues?.[valueType]?.sort((v1, v2) => v1.value?.localeCompare(v2.value)) ?? [];

        if (valueType === 'CUSTOMER') {
            options = [{ id: 'new_customer', value: t('Create a new customer') }, ...options];
        }

        return options;
    };

    const MappingValueComboBox = ({ mapping }: { mapping: Mapping }) => {
        const getSelectedValue = (mapping: Mapping) => {
            let value: MappingValue | undefined = undefined;
            if (!mapping) value = undefined;
            else if (mapping.state === 'mapped') {
                let isAcomba: boolean = config?.type == 'Acomba';
                if (isAcomba) {
                    let acombaValue: MappingValue | undefined = undefined;
                    if (type.type === 'mapping' && mappingValues?.[type.valueClass!])
                        acombaValue = mappingValues[type.valueClass!].find((mv) => {
                            return (
                                mv.value?.substring(0, 30) === mapping.externalEntityId ||
                                mv.id === mapping.externalEntityId
                            );
                        })!;
                    console.log('acombaValue', acombaValue);
                    value = acombaValue;
                } else if (type.type === 'mapping' && mappingValues?.[type.valueClass!])
                    value = mappingValues[type.valueClass!].find((mv) => mv.id === mapping.externalEntityId)!;
            }

            return value;
        };

        return (
            <Box sx={{ paddingTop: '3px' }}>
                <Autocomplete
                    disablePortal
                    size='small'
                    disableClearable={true}
                    multiple={false}
                    options={prepareOptions()}
                    renderInput={(params) => <TextField {...params} />}
                    getOptionLabel={(v) => (typeof v === 'string' ? v : v.value)}
                    filterOptions={createFilterOptions({
                        stringify: (option) => {
                            let str = option.value;
                            if (option.description) str += ' ' + option.description;
                            if (option.address?.Line1) str += ' ' + option.address.Line1;
                            if (option.address?.City) str += ' ' + option.address.City;

                            return str;
                        },
                    })}
                    renderOption={(props, v) => {
                        if (v.address && (v.address.Line1 || v.address.City)) {
                            return (
                                <li {...props} key={v.id}>
                                    <div style={{ display: 'block' }}>
                                        <div>{v.value}</div>
                                        <div
                                            style={{
                                                color: 'gray',
                                                fontSize: '10pt',
                                            }}
                                        >
                                            {v.address.Line1 ?? t('Unknown address')}{' '}
                                            {v.address.City ?? t('Unknown city')}
                                        </div>
                                    </div>
                                </li>
                            );
                        } else if (v.description) {
                            return (
                                <li {...props} key={v.id}>
                                    <div style={{ display: 'block' }}>
                                        <div>{v.value}</div>
                                        <div
                                            style={{
                                                color: 'gray',
                                                fontSize: '10pt',
                                            }}
                                        >
                                            {v.description}
                                        </div>
                                    </div>
                                </li>
                            );
                        } else {
                            return (
                                <li {...props} key={v.id}>
                                    {v.value}
                                </li>
                            );
                        }
                    }}
                    defaultValue={getSelectedValue(mapping)}
                    onChange={async (event, value: MappingValue) => {
                        console.log('value', value);
                        console.log('mapping', mapping);
                        if (value && value.id === 'new_customer') {
                            console.log('open popup');
                            //convert value to string and set newCustomerDetails with the mapping value dynamically

                            confirmDialog(
                                t('Confirm New Customer'),
                                t('Do you really want to create a new customer for ') + mapping.label + '?',
                                t('Yes, create new customer'),
                                t('No'),
                                'warning',
                                async () => {
                                    await handleCreateCustomer(mapping);
                                },
                            );

                            return;
                        }
                        apiClient.saveMappings([
                            {
                                integrationIdAndType: mapping.integrationIdAndType,
                                entityId: mapping.entityId,
                                externalEntityId: value.id,
                            },
                        ]);
                        dispatch({
                            type: ActionTypes.SAVE_MAPPING,
                            payload: {
                                class: type.class,
                                entityId: mapping.entityId,
                                externalEntityId: value.id,
                            },
                        });
                    }}
                />
            </Box>
        );
    };

    const getSourceLabel = (params: Mapping) => {
        return params.label;
    };

    const rowRenderer = ({ index, style }: { index: number; style: CSSProperties }) => {
        const mappings = getEffectiveMappings();
        if (mappings.length <= index) return <>problems...</>;
        const mapping = mappings[index];
        return (
            <div
                key={index}
                style={{
                    ...style,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderBottom: '1px solid #D3D3D3',
                    padding: '5px',
                }}
            >
                <div style={{ flex: 1 }}>{getSourceLabel(mapping!)}</div>
                {type.extraColumns?.map((c) => (
                    <Typography key={'custom' + c.id} style={{ flex: 1 }}>
                        <span key={c.id + '-' + index}>
                            {c.getValue ? c.getValue(mapping) : (mapping as any)[c.id]}
                        </span>
                    </Typography>
                ))}
                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center' }}>
                    <div style={{ flex: 1 }}>
                        <MappingValueComboBox mapping={mapping!} />
                    </div>
                    <div>
                        <Tooltip title='Reset'>
                            <IconButton
                                onClick={() => {
                                    confirmDialog(
                                        t('Confirm Reset'),
                                        t('Are you sure you want to reset this mapping?'),
                                        t('Yes, reset'),
                                        t('No'),
                                        'warning',
                                        async () => {
                                            const isReset = await apiClient.resetMapping([
                                                {
                                                    integrationIdAndType: mapping.integrationIdAndType,
                                                    entityId: mapping.entityId,
                                                    externalEntityId: mapping.externalEntityId ?? '',
                                                },
                                            ]);
                                            if (isReset) {
                                                setOpenSnackbarMessage(
                                                    t('{{mappingType}} is reseting...', {
                                                        mappingType: t(type.label),
                                                    }) ?? '',
                                                );
                                            }
                                        },
                                    );
                                }}
                            >
                                <ReplayIcon />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={t('Copy workflowId to help with debug')}>
                            <IconButton
                                onClick={(event) => {
                                    const workflowId = mapping.integrationIdAndType + '_' + mapping.entityId;
                                    navigator.clipboard.writeText(workflowId);
                                    setOpenSnackbarMessage('WorkflowId copied to clipboard');
                                }}
                            >
                                <ContentCopyIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            </div>
        );
    };

    function getEffectiveMappings(): Mapping[] {
        // Vérifiez si 'mappings' est défini et a la clé 'type.class'
        if (!mappings || !mappings[type.class]) return [];

        // Filtrez et retournez les mappings comme avant
        let filteredMappings = mappings[type.class];
        if (searchTerm) {
            filteredMappings = filteredMappings.filter((mapping) =>
                mapping.label.toLowerCase().includes(searchTerm.toLowerCase()),
            );
        }
        if (showMissingsOnly) {
            return filteredMappings.filter((m) => m.state !== 'mapped');
        }
        return filteredMappings;
    }

    return (
        <div>
            {creatingCustomer && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 9999,
                    }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <CircularProgress size={60} />
                        <Typography variant='h6' style={{ marginTop: '20px' }}>
                            {t('Creating customer...')}
                        </Typography>
                    </div>
                </div>
            )}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '0 0px 10px 0px',
                    borderBottom: '1px solid #e0e0e0',
                    position: 'relative',
                }}
            >
                <div style={{ display: 'flex', alignItems: 'baseline', gap: '14px' }}>
                    <BulkActionsList
                        isDisabled={getEffectiveMappings().length === 0}
                        actions={[
                            {
                                text: t(`Update ${config!.type === 'Qbo' ? 'Quickbooks' : 'Acomba'}'s list`),
                                onClick: handleUpdateMappingValues,
                                tooltip: t(
                                    'Refresh the list of items in the list by retrieving the latest data from {{software}}.',
                                    { software: config!.type === 'Qbo' ? 'Quickbooks' : 'Acomba' },
                                ),
                            },
                            {
                                text: t("Update PivoHub's list"),
                                onClick: handlePivoHubUpdateList,
                                tooltip: t(
                                    'Refresh the list of items in the list by retrieving the latest data from PivoHub.',
                                ),
                            },
                        ]}
                    />
                </div>
                <div style={{ display: 'flex', gap: '12px', width: 'stretch', paddingLeft: '20px' }}>
                    <div className='label'>{t('Show only missing mappings')}</div>
                    <input
                        checked={showMissingsOnly}
                        type='checkbox'
                        onChange={(event) => {
                            const checked = event.target.checked;
                            setShowMissingsOnly(checked);
                        }}
                    />
                </div>
                <TextField
                    label={t('Search')}
                    variant='outlined'
                    size='small'
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{ marginLeft: '10px' }}
                />
            </div>
            <br />
            <div style={{ position: 'relative', height: '100vh' }}>
                <div style={{ position: 'sticky', top: 0, zIndex: 100 }}>
                    <Paper
                        elevation={2}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            padding: '10px',
                        }}
                    >
                        <div style={{ flex: 1 }}>{t('Name')}</div>
                        {(type.extraColumns ?? []).map((c) => (
                            <div key={`head-${c.id}`} style={{ flex: 1 }}>
                                {t(c.label)}
                            </div>
                        ))}
                        <div style={{ flex: 1 }}>{config!.type === 'Qbo' ? 'Quickbooks' : 'Acomba'}</div>
                    </Paper>
                </div>
                <AutoSizer>
                    {({ height, width }) => (
                        <List
                            height={height ?? 0}
                            itemCount={getEffectiveMappings().length}
                            itemSize={55}
                            width={width ?? 0}
                        >
                            {rowRenderer}
                        </List>
                    )}
                </AutoSizer>
            </div>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(openSnackbarMessage)}
                autoHideDuration={2000}
                onClose={() => setOpenSnackbarMessage(undefined)}
                message={t(openSnackbarMessage ?? '')}
            />
        </div>
    );
}
