import { Dialog, DialogTitle, DialogActions, DialogContent, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'usehooks-ts';
import { useLocalization } from '../contexts/LocalizationContext';
import { ChangeLogEntry, logs as logsData } from './changelogsDatas'; // Import des données

function Changelog() {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const { lang } = useLocalization();
    const [lastChangelogDate, setLastChangelogDate] = useLocalStorage<string>('progyconnect-lastChangelog', '');
    //Initialiser les logs depuis la variable newLogs
    const [logs] = useState<ChangeLogEntry[]>(logsData);

    const handleImageLoad = (e: { target: any }) => {
        const image = e.target;
        // Calculer la hauteur après le redimensionnement à 300px de large
        const heightAfterResize = image.naturalHeight / (image.naturalWidth / 300);
        if (heightAfterResize < 50) {
            // Si la hauteur après redimensionnement est inférieure à 50px
            image.style.maxWidth = '550px';
        } else {
            // Sinon, conserver maxWidth à 300px
            image.style.maxWidth = '300px';
        }
        image.style.height = 'auto';
    };

    useEffect(() => {
        if (lastChangelogDate) {
            const lastChangelogDateTimestamp = new Date(lastChangelogDate).getTime();
            const lastLogDate = logs[0].date;
            if (typeof lastLogDate === 'string') {
                setOpen(true);
            } else if (lastChangelogDateTimestamp < lastLogDate) {
                setOpen(true);
            }
        } else {
            setOpen(true);
        }
    }, [lastChangelogDate, logs]);

    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='lg'>
            <DialogTitle>
                <h4>{t('Updates, fixes and improvements to ProgyLink.')}</h4>
            </DialogTitle>
            <DialogContent>
                <div className='changelog-list'>
                    {logs.map((entry) => (
                        <div key={entry.date} className='changelog-item'>
                            <h3>{formatDate(entry.date, lang.code)}</h3>
                            {Object.entries(entry[lang.code]).map(([type, changes]) => (
                                <div key={type}>
                                    {changes.length > 0 && (
                                        <>
                                            <h4>{t(capitalizeFirstLetter(type))}:</h4>
                                            <ul>
                                                {changes.map((change, index) => (
                                                    <li key={index}>
                                                        {typeof change === 'string' ? (
                                                            change
                                                        ) : (
                                                            <>
                                                                {change.destination && (
                                                                    <span>
                                                                        <strong>{change.destination}</strong>
                                                                    </span>
                                                                )}
                                                                <div
                                                                    style={
                                                                        change.destination ? { marginLeft: '20px' } : {}
                                                                    }
                                                                >
                                                                    {change.title && <strong>{change.title}</strong>}
                                                                    <p>{change.description}</p>
                                                                    {change.images?.map((image, index) => (
                                                                        <img
                                                                            key={index}
                                                                            src={image}
                                                                            alt={change.title}
                                                                            onLoad={handleImageLoad}
                                                                        />
                                                                    ))}
                                                                </div>
                                                            </>
                                                        )}
                                                    </li>
                                                ))}
                                            </ul>
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    variant='contained'
                    size='small'
                    onClick={() => {
                        setLastChangelogDate(logs?.[0].date.toString());
                        setOpen(false);
                    }}
                >
                    {t("Don't show again")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function formatDate(dateInput: string | number, lang: 'en' | 'fr'): string {
    if (typeof dateInput === 'string') {
        return dateInput;
    }

    const date = new Date(dateInput);
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(lang, options);
}

function capitalizeFirstLetter(string: string) {
    return string.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
        return str.toUpperCase();
    });
}

export default Changelog;
