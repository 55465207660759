import { CircularProgress, IconButton, TextField, Snackbar } from '@mui/material';
import { IntegrationContextType, useIntegration } from '../../contexts/IntegrationProvider';

import Button from '@mui/material/Button';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AgentConfigAndState } from '@progyconnect/webapp-types';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../redux/state';
import { ActionTypes } from '../../redux/Action';
import { Action, Dispatch } from 'redux';

export default function ConfigureAcomba({ waitForConnection }: { waitForConnection: boolean }) {
    const { apiClient } = useIntegration() as IntegrationContextType;
    const { t } = useTranslation();
    const isConnected = useSelector<State, boolean | undefined>((state) => state.isConnected);
    const dispatch = useDispatch<Dispatch<Action>>();

    const [startTime] = useState<number>(Date.now());
    const [agentConfiguration, setAgentConfiguration] = useState<AgentConfigAndState>();
    const [downloadUrl, setDownloadUrl] = useState<string>();
    const [openCopyWorkflowSnackbar, setOpenCopyWorkflowSnackbar] = useState(false);

    useEffect(() => {
        const fetchAgentConfig = async () => {
            const config = await apiClient.getAgentConfiguration();
            if (!config || config.state === 'not_ready') {
                setTimeout(() => {
                    fetchAgentConfig();
                }, 5000);
            } else setAgentConfiguration(config);
        };

        fetchAgentConfig();
    }, [apiClient, startTime]);

    useEffect(() => {
        if (isConnected || !waitForConnection) return; //already connected, no need to wait for connection

        const refetchIsConnected = async () => {
            const isConnected = await apiClient.isConnected();
            if (!isConnected) {
                setTimeout(() => {
                    refetchIsConnected();
                }, 5000);
            } else {
                dispatch({
                    type: ActionTypes.UPDATE_IS_CONNECTED,
                    payload: {
                        isConnected: true,
                    },
                });
            }
        };

        refetchIsConnected();
    }, [apiClient, startTime]);

    useEffect(() => {
        const fetchDownloadUrl = async () => {
            const url = await apiClient.getAgentDownloadUrl();
            setDownloadUrl(url);
        };

        fetchDownloadUrl();
    }, [apiClient]);

    const AgentConfig = () => {
        if (!agentConfiguration || agentConfiguration.state === 'not_ready')
            return (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <p>{t('Waiting for configuration...')}</p>
                    <CircularProgress />
                </div>
            );

        let internalConfig =
            typeof agentConfiguration.config === 'string'
                ? JSON.parse(agentConfiguration.config)
                : agentConfiguration.config;
        const agentConfig = JSON.stringify(internalConfig, null, 2);
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ position: 'relative', width: 'fit-content' }}>
                    <TextField
                        style={{ width: '1000px' }}
                        rows={agentConfig.split('\n').length}
                        variant='outlined'
                        value={agentConfig}
                        multiline={true}
                    ></TextField>
                    <IconButton
                        style={{ position: 'absolute', right: '10px', top: '10px' }}
                        onClick={async () => {
                            navigator.clipboard.writeText(agentConfig);
                            setOpenCopyWorkflowSnackbar(true);
                        }}
                    >
                        <ContentCopyIcon />
                        {t('Copy')}
                    </IconButton>
                </div>
            </div>
        );
    };

    const DownloadAgent = () => {
        if (!downloadUrl)
            return (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <p>{t('Waiting for agent download url...')}</p>
                    <CircularProgress />
                </div>
            );
        return (
            <div style={{ margin: '50px', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <Button
                    variant='contained'
                    onClick={() => {
                        window.open(downloadUrl, '_blank');
                    }}
                >
                    {t('Download agent')}
                </Button>
            </div>
        );
    };

    const WaitingForConnection = () => {
        if (waitForConnection)
            return (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <p>{t('Waiting for connection...')}</p>
                    <CircularProgress />
                </div>
            );
        return <></>;
    };

    return (
        <div style={{ margin: '50px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <AgentConfig />
                <DownloadAgent />
                <WaitingForConnection />
            </div>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={openCopyWorkflowSnackbar}
                autoHideDuration={2000}
                onClose={() => setOpenCopyWorkflowSnackbar(false)}
                message={t('configuration copied to clipboard')}
            />
        </div>
    );
}
