import { useSearchParams } from 'react-router-dom';
import { useCallback, useState } from 'react';

export const useQueryArg = (argName: string, defaultValue?: string) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const argValue: string = typeof window !== 'undefined' ? searchParams.get(argName) ?? '' : '';

    const setValue = useCallback(
        (value: string | undefined) => {
            if (value === '' || value === undefined || value === null) {
                searchParams.delete(argName);
            } else {
                searchParams.set(argName, value);
            }

            setSearchParams(searchParams);
        },
        [argName, searchParams, setSearchParams],
    );

    if (isLoading) setIsLoading(false);

    return [argValue ?? defaultValue, setValue, isLoading] as const;
};
