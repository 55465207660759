// Reducer.ts
import { State } from './state';
import { Action, ActionTypes } from './Action';
import { Mapping } from '@progyconnect/webapp-types';

const initialState: State = {
    mappings: {},
    mappingValues: {},
    config: undefined,
    maintenance: undefined,
    isConnected: undefined,
};

export function reducer(state: State = initialState, action: Action): State {
    switch (action.type) {
        case ActionTypes.SET_STATE_NO_MAPPINGS:
            return {
                config: action.payload.config,
                maintenance: action.payload.maintenance,
                isConnected: action.payload.isConnected,
            };

        case ActionTypes.SAVE_CONFIG:
            if (!state?.config) throw new Error('Config needs to be set before updating it.');

            return {
                ...state,
                config: {
                    ...state.config,
                    orderWorkflowConfigs: {
                        ...state.config.orderWorkflowConfigs,
                        transmitMode: action.payload.transmitMode ?? state.config.orderWorkflowConfigs.transmitMode,
                        status: action.payload.status ?? state.config.orderWorkflowConfigs.status,
                    },
                },
            };

        case ActionTypes.SELECT_INTEGRATION_TYPE:
            if (!state?.config) throw new Error('Config needs to be set before updating it.');

            return {
                ...state,
                config: {
                    ...state.config,
                    type: action.payload.integrationType,
                },
            };

        case ActionTypes.SAVE_MAPPING:
            if (!state?.config || !state.mappings) throw new Error('Config needs to be set before updating it.');
            return {
                ...state,
                mappings: {
                    ...state.mappings,
                    [action.payload.class]: state.mappings[action.payload.class].map((mapping) => {
                        const newMapping = { ...mapping };
                        if (newMapping.entityId === action.payload.entityId) {
                            newMapping.state = 'mapped';
                            newMapping.externalEntityId = action.payload.externalEntityId;
                        }
                        return newMapping;
                    }),
                },
            };

        case ActionTypes.UPDATE_IS_CONNECTED:
            return {
                ...state,
                isConnected: action.payload.isConnected,
            };

        case ActionTypes.SET_MAPPINGS:
            return {
                ...state,
                mappings: action.payload.mappings,
                mappingValues: action.payload.mappingValues,
            };

        case ActionTypes.SET_CONFIG:
            return {
                ...state,
                config: action.payload.config,
            };

        case ActionTypes.ADD_MISSING_MAPPING:
            if (!state?.config || !state.mappings) throw new Error('Config needs to be set before updating it.');

            const mappingsByType = action.payload.mappings.reduce((acc, mapping) => {
                const type = mapping.integrationIdAndType.split('_')[1].toLowerCase();
                if (!acc[type]) {
                    acc[type] = [];
                }
                acc[type].push(mapping);
                return acc;
            }, {} as Record<string, Mapping[]>);

            //remove existing mappings
            Object.keys(mappingsByType).forEach((type) => {
                const existingMappings = state.mappings![type] ?? [];
                mappingsByType[type] = mappingsByType[type].filter(
                    (mapping) =>
                        !existingMappings.find((existingMapping) => existingMapping.entityId === mapping.entityId),
                );
            });

            const newState = {
                ...state,
                mappings: {
                    ...state.mappings,
                },
            };

            Object.keys(mappingsByType).forEach((type) => {
                newState.mappings[type] = [...(newState.mappings[type] ?? []), ...mappingsByType[type]];
            });

            return newState;

        case ActionTypes.SET_MAINTENANCE:
            return {
                ...state,
                maintenance: action.payload.maintenance,
            };

        case ActionTypes.SET_MAPPINGS_BY_TYPE:
            return {
                ...state,
                mappings: {
                    ...state.mappings,
                    [action.payload.class]: action.payload.mappings,
                },
            };

        case ActionTypes.UPDATE_MAPPING_VALUES:
            return {
                ...state,
                mappingValues: {
                    ...state.mappingValues,
                    [action.payload.type]: action.payload.mappingValues,
                },
            };
        default:
            return state;
    }
}
