import { Action, Dispatch } from 'redux';
import { CSSProperties, RefObject, SetStateAction, useEffect, useRef, useState } from 'react';
import {
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    IconButton,
    ListItem,
    ListItemButton,
    ListItemText,
    Menu,
    MenuItem,
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
} from '@mui/material';
import type {
    ColumnFilter,
    DynamoKey,
    Invoicing,
    InvoicingType,
    Mapping,
    WebAppConfig,
    WebappEntryType,
} from '@progyconnect/webapp-types';
import { HelpDialog, openHelpDialog } from '../components/HelpDialog';
import { IntegrationContextType, useIntegration } from '../contexts/IntegrationProvider';
import { useDispatch, useSelector } from 'react-redux';

import { ActionTypes } from '../redux/Action';
import type { AtlassianTicket } from '@progyconnect/webapp-types';
import AutoSizer from 'react-virtualized-auto-sizer';
import BulkActionsList from '../components/BulkActionsList';
import Button from '@mui/material/Button';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import HelpIcon from '@mui/icons-material/Help';
import InfiniteLoader from 'react-window-infinite-loader';
import { InvoicingRefreshTracker } from './InvoicingRefreshTracker';
import { FixedSizeList as List } from 'react-window';
import RefreshIcon from '@mui/icons-material/Refresh';
import ReplayIcon from '@mui/icons-material/Replay';
import { State } from '../redux/state';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import WarningIcon from '@mui/icons-material/Warning';
import { confirmDialog } from '../components/ConfirmDialog';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocalization } from '../contexts/LocalizationContext';
import { useTranslation } from 'react-i18next';

export default function Invoicings({ entryType }: { entryType: WebappEntryType }) {
    const { apiClient } = useIntegration() as IntegrationContextType;
    const { t } = useTranslation();
    const { lang } = useLocalization();
    const config = useSelector<State, WebAppConfig | undefined>((state) => state.config);
    const invoicingRefreshTrackerRef = useRef<InvoicingRefreshTracker>();
    const dispatch = useDispatch<Dispatch<Action>>();
    const [mode, setMode] = useState<WebAppConfig['orderWorkflowConfigs']['autoMode'] | undefined>(
        config?.orderWorkflowConfigs?.autoMode,
    );

    useEffect(() => {
        console.log("La valeur initiale de ToggleButtonGroup 'mode':", mode);
    }, [mode]);

    const { user } = useAuth0();

    const invoicings = useRef<(Invoicing | null)[]>([]);

    const [refreshTrigger, setRefreshTrigger] = useState<number>(0);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleTransfer = () => {
        // Logique pour le transfert

        confirmDialog(
            t('Transfert {{invoicingType}}s?', { invoicingType: t(entryType.label) }),
            t('Are you sure you want to transfer these {{invoicingType}}?', {
                invoicingType: t(entryType.label),
            }),
            t('Yes'),
            t('No'),
            'warning',
            () => {
                const entityIds = Object.keys(rowSelection).filter((k) => k !== 'deliveryRouteAndDate');

                const selectedInvoicings = entityIds.map((o) =>
                    invoicings.current?.find((i) => i !== null && i.entityId === o),
                );

                const transferrableInvoicings = selectedInvoicings.filter(
                    (o) => !!o && o.state === 'pending-confirmation',
                );

                apiClient.transmitInvoicing(
                    entryType.class as InvoicingType,
                    transferrableInvoicings.map((o) => o!.entityId),
                );
                for (const invoicing of transferrableInvoicings) {
                    if (!invoicing || !invoicingRefreshTrackerRef.current) continue;
                    updateInvoicingStatus(invoicing, 'processing');
                    invoicingRefreshTrackerRef.current.addInvoicing(invoicing);
                }

                setRowSelection({});
            },
        );

        handleClose();
    };

    const handleReset = () => {
        // Logique pour le reset
        confirmDialog(
            t('Reset {{invoicingType}}s?', { invoicingType: t(entryType.label) }),
            t('Are you sure you want to reset these {{invoicingType}}?', {
                invoicingType: t(entryType.label),
            }),
            t('Yes'),
            t('No'),
            'warning',
            () => {
                const entityIds = Object.keys(rowSelection).filter((k) => k !== 'deliveryRouteAndDate');

                const selectedInvoicings = entityIds.map((o) =>
                    invoicings.current?.find((i) => i !== null && i.entityId === o),
                );

                for (const invoicing of selectedInvoicings) {
                    if (!invoicing || !invoicingRefreshTrackerRef.current) continue;
                    apiClient.resetInvoicing(entryType.class as InvoicingType, invoicing.entityId);
                    invoicingRefreshTrackerRef.current!.addInvoicing(invoicing);
                    updateInvoicingStatus(invoicing, 'processing');
                }

                setRowSelection({});
            },
        );
        handleClose();
    };

    const handleHelp = () => {
        if (entryType.class !== 'ORDER') {
            alert(t('Help is only available for orders'));
            return;
        }

        const orderNumbers = Object.keys(rowSelection).map((entityId) => {
            const selectedInvoicing = rowSelection[entityId];
            return selectedInvoicing.entityNumber; // Utiliser le numéro de commande ici
        });

        openHelpDialog(orderNumbers, async (userQuery) => {
            //Générer un tableau avec les numéros de commande et les workflowId associés
            const orderNumbersAndWorkflowIds = Object.keys(rowSelection).map((entityId) => {
                const selectedInvoicing = rowSelection[entityId];
                return (
                    selectedInvoicing.entityNumber +
                    ' : ' +
                    selectedInvoicing.integrationIdAndType +
                    '_' +
                    selectedInvoicing.entityId
                );
            });

            // Création d'une constante qui contiendra tous les éléments à envoyer au support
            const ticket: AtlassianTicket = {
                subject: "[PivoHub] - Demande d'aide / Request for help",
                orderNumbersAndWorkflowIds: orderNumbersAndWorkflowIds,
                userQuery: userQuery,
                userEmail: user?.email || user?.name,
            };

            const success = await apiClient.createAtlassianTicket(ticket);

            // Provide a feedback message to the user
            if (success)
                setOpenSnackbarMessage(t('Your query has been submitted. We will get back to you shortly.') ?? '');
            else setOpenSnackbarMessage(t('An error occurred. Please try again.') ?? '');
        });
    };

    useEffect(() => {
        if (!apiClient) return;

        const refreshInvoicings = (completedInvoicings: Invoicing[]) => {
            fetchMissingMappingsForPendingInvoicings(completedInvoicings);
            completedInvoicings.forEach((invoicing) => {
                const index = invoicings.current.findIndex((o) => o !== null && o.entityId === invoicing.entityId);
                if (index >= 0) {
                    invoicings.current[index] = invoicing;
                }
            });

            setRefreshTrigger((prev) => prev + 1);
        };

        const getUpdatedInvoicings = (invoicingsToUpdate: Invoicing[]) => {
            return apiClient.getInvoicingsByIds(
                entryType.class as InvoicingType,
                invoicingsToUpdate.map((o) => o.entityId),
            );
        };

        const invoicingRefreshTracker = new InvoicingRefreshTracker(refreshInvoicings, getUpdatedInvoicings);

        invoicingRefreshTrackerRef.current = invoicingRefreshTracker;
        init();

        return () => {
            invoicingRefreshTracker.stop();
        };
    }, [apiClient, lang]);

    const lastSortKey = useRef<DynamoKey | undefined>();

    const grouping: RefObject<HTMLInputElement> = useRef(null);
    const showMissingsOnly: RefObject<HTMLInputElement> = useRef(null);
    const filterByInvoicingNumber = useRef<string>('');

    useEffect(() => {
        const handleCheckboxChange = () => {
            init();
        };

        grouping.current?.addEventListener('change', handleCheckboxChange);
        showMissingsOnly.current?.addEventListener('change', handleCheckboxChange);

        return () => {
            grouping.current?.removeEventListener('change', handleCheckboxChange);
            showMissingsOnly.current?.removeEventListener('change', handleCheckboxChange);
        };
    }, []);

    const [openSnackbarMessage, setOpenSnackbarMessage] = useState<string | undefined>();

    //------------------------------------------------------

    const getColumnFilters = (filterByInvoicingNumber: string, showMissingsOnly: boolean): ColumnFilter[] => {
        if (filterByInvoicingNumber !== undefined && filterByInvoicingNumber != '')
            return [{ id: 'invoicingNumber', value: filterByInvoicingNumber }];

        if (showMissingsOnly) return [{ id: 'state', value: 'pending' }];

        return [];
    };

    const [rowSelection, setRowSelection] = useState<{ [key: string]: Invoicing }>({});

    const init = () => {
        //clear invoicings when filtering conditions change
        invoicings.current = [];
        lastSortKey.current = undefined;
        if (invoicingRefreshTrackerRef.current) invoicingRefreshTrackerRef.current.clear();
        fetchMoreInvoicings(true);
    };

    const [isNextPageLoading, setIsNextPageLoading] = useState(false);

    //fetch more invoicings when scrolling to the bottom of the list
    const fetchMoreInvoicings = async (firstRun: boolean) => {
        if (!apiClient || !invoicingRefreshTrackerRef.current) return;

        setIsNextPageLoading(true);

        const response = await apiClient.getInvoicings(
            entryType.class as InvoicingType,
            100,
            lastSortKey.current,
            getColumnFilters(filterByInvoicingNumber.current, showMissingsOnly.current?.checked ?? false),
            grouping.current?.checked ? ['route'] : undefined,
        );

        if (firstRun || lastSortKey.current !== response?.LastEvaluatedKey) {
            lastSortKey.current = response?.LastEvaluatedKey;
            if (response?.items?.length > 0) {
                //find invoicings not already in invoicings list

                const newInvoicings: (Invoicing | null)[] = response.items.filter(
                    (o1) => invoicings.current.find((o) => o?.entityId === o1.entityId) === undefined,
                );

                //add new invoicings that are already 'processing' to the refresh tracker
                newInvoicings
                    .filter((o) => o !== null && isTemporaryState(o.state))
                    .forEach((o) => o !== null && invoicingRefreshTrackerRef.current!.addInvoicing(o));

                if (!response.LastEvaluatedKey) newInvoicings.push(null);

                invoicings.current = [...invoicings.current, ...newInvoicings];
            } else if (lastSortKey.current === response?.LastEvaluatedKey) {
                //do nothing, ignore this duplicate response
            } else {
                invoicings.current = [...invoicings.current, null];
            }
        }

        setRefreshTrigger((prev) => prev + 1);

        setIsNextPageLoading(false);
    };

    const isTemporaryState = (state: Invoicing['state']) => {
        return (
            state === 'processing' ||
            state === 'pending-invalidated' ||
            state === 'pending-mapping' ||
            state === 'invalidated'
        );
    };

    //fetch missing mappings for pending invoicings
    const fetchMissingMappingsForPendingInvoicings = async (newInvoicings: (Invoicing | null)[]) => {
        const pendingInvoicings = newInvoicings.filter(
            (o) => o !== null && o.state === 'pending-mapping',
        ) as Invoicing[];
        for (const pendingInvoicing of pendingInvoicings) {
            const blockingMappings = await apiClient.blockingMapping(
                entryType.class as InvoicingType,
                pendingInvoicing.entityId,
                lang.code === 'fr' ? 'FR' : 'EN',
            );
            dispatch({
                type: ActionTypes.ADD_MISSING_MAPPING,
                payload: {
                    mappings: blockingMappings,
                },
            });
        }
    };

    //show popup with the list of blocking invoicings
    const [blockingMapping, setBlockingMapping] = useState<Mapping[]>();

    const formatter = new Intl.NumberFormat(lang.code === 'fr' ? 'fr-CA' : 'en-CA', {
        style: 'currency',
        currency: 'CAD',
    });

    const getDocNumberLabel = (invoicing: Invoicing, maxLength?: number) => {
        if (maxLength && invoicing?.entityNumber?.length > maxLength) {
            const shortenedDocNum =
                invoicing.entityNumber.substring(0, 5) +
                '...' +
                invoicing.entityNumber.substring(invoicing.entityNumber.length - 5, invoicing.entityNumber.length);
            return shortenedDocNum;
        }
        return invoicing?.entityNumber ?? t('N/A');
    };
    const getCustomerLabel = (invoicing: Invoicing) => {
        return invoicing?.clientName ?? t('N/A');
    };

    const getAmountLabel = (invoicing: Invoicing) => {
        if (!invoicing.amount) return t('N/A');
        return formatter.format(invoicing.amount);
    };

    const getStatusLabel = (invoicing: Invoicing) => {
        if (invoicing.state === 'invalidated') return <span>{t('Invalidated')}</span>;
        if (invoicing.state === 'mapped') return <span>{t('Transferred')}</span>;
        if (invoicing.state === 'processing') return <span>{t('Processing')}</span>;
        if (invoicing.state === 'canceled') return <span>{t('Canceled')}</span>;
        if (invoicing.state === 'error') return <span>{t('Error')}</span>;
        if (invoicing.state === 'ignored') return <span>{t('Ignored')}</span>;
        if (invoicing.state === 'pending-invalidated') return <span>{t('Invalidated')}</span>;
        if (invoicing.state === 'pending-mapping')
            return (
                <Button
                    onClick={async () => {
                        const blockingMappings = await apiClient.blockingMapping(
                            entryType.class as InvoicingType,
                            invoicing.entityId,
                            lang.code === 'fr' ? 'FR' : 'EN',
                        );
                        const mappings = blockingMappings?.filter((m) => m?.state === 'pending-mapping');
                        if ((mappings?.length ?? 0) > 0) setBlockingMapping(mappings);
                    }}
                >
                    <WarningIcon color='warning' />
                </Button>
            );
        if (invoicing.state === 'pending-confirmation')
            return (
                <div>
                    <Button
                        variant='contained'
                        size='small'
                        onClick={() => {
                            confirmDialog(
                                t('Transfert {{invoicingType}}?', { invoicingType: t(entryType.label) }),
                                t('Are you sure you want to transfer this {{invoicingType}}?', {
                                    invoicingType: t(entryType.label),
                                }),
                                t('Yes'),
                                t('No'),
                                'warning',
                                () => {
                                    if (invoicing.entityId && invoicingRefreshTrackerRef.current) {
                                        apiClient.transmitInvoicing(entryType.class as InvoicingType, [
                                            invoicing.entityId,
                                        ]);
                                        updateInvoicingStatus(invoicing, 'processing');
                                        invoicingRefreshTrackerRef.current.addInvoicing(invoicing);
                                    }
                                },
                            );
                        }}
                        style={{ marginRight: '10px' }}
                    >
                        {t('Transfert')}
                    </Button>
                </div>
            );
    };

    const getLastOperationLabel = (invoicing: Invoicing): string[] => {
        if (!invoicing.lastUpdateDate) return [t('N/A')];

        const date = new Date(invoicing.lastUpdateDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const formattedDate = [`${year}-${month}-${day}`, `${hours}:${minutes}:${seconds}`];
        return formattedDate;
    };

    function titleCase(string: string) {
        var sentence = string.toLowerCase().split(' ');
        for (var i = 0; i < sentence.length; i++) {
            sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
        }
        return sentence;
    }

    function updateInvoicingStatus(invoicing: Invoicing, status: Invoicing['state']) {
        const index = invoicings.current.findIndex((o) => o !== null && o.entityId === invoicing.entityId);
        if (index >= 0) {
            invoicings.current[index] = {
                ...invoicings.current[index],
                state: status,
            } as Invoicing;
        }

        setRefreshTrigger((prev) => prev + 1);
    }

    const Header = ({}: {}) => {
        return (
            <Paper
                elevation={2}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '10px',
                }}
            >
                <Checkbox
                    style={{ flex: 1 }}
                    checked={
                        invoicings.current?.length > 0 &&
                        Object.keys(rowSelection).length === invoicings.current?.length &&
                        !hasNextPage
                    }
                    indeterminate={
                        Object.keys(rowSelection).length > 0 &&
                        (hasNextPage || Object.keys(rowSelection).length < (invoicings.current?.length ?? 0))
                    }
                    onChange={(event) => {
                        const nbInvoicings = invoicings.current.filter((o) => o !== null).length;
                        if (Object.keys(rowSelection).length < nbInvoicings) {
                            setRowSelection(
                                invoicings.current.reduce((acc, invoicing) => {
                                    if (invoicing) {
                                        acc[invoicing.entityId] = invoicing;
                                    }
                                    return acc;
                                }, {} as typeof rowSelection),
                            );
                        } else {
                            setRowSelection({} as typeof rowSelection);
                        }
                    }}
                />
                {(entryType.extraColumns ?? []).map((c) => (
                    <div key={`head-${c.id}`} style={{ flex: 4 }}>
                        {t(c.label)}
                    </div>
                ))}
                <div key={'head-docno'} style={{ flex: 3 }}>
                    {t('Doc No.')}
                </div>
                <div key={'head-customer'} style={{ flex: 6 }}>
                    {t('Customer')}
                </div>
                <div key={'head-amount'} style={{ flex: 3 }}>
                    {t('Amount')}
                </div>
                <div key={'head-status'} style={{ flex: 3 }}>
                    {t('Status')}
                </div>
                <div key={'head-lastop'} style={{ flex: 4 }}>
                    {t('Last operation')}
                </div>
                <div style={{ flex: 2.5 }}></div>
            </Paper>
        );
    };

    const rowRenderer = ({ index, style }: { index: number; style: CSSProperties }) => {
        if (invoicings.current.length <= index) return <>problems...</>;
        const invoicing = invoicings.current[index];
        if (invoicing === null) return <></>;
        return (
            <div
                key={invoicing!.entityId}
                style={{
                    ...style,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    borderBottom: '1px solid #D3D3D3',
                    gap: '10px',
                }}
            >
                <Checkbox
                    key={'group-checkbox'}
                    style={{ flex: 1 }}
                    checked={!!rowSelection[invoicing!.entityId]}
                    onChange={(event) => {
                        const newSelection = { ...rowSelection };

                        if (event.target.checked) {
                            if (grouping.current?.checked) {
                                const route = (invoicing as any)['routeIdentifier'];
                                const items = invoicings.current.filter(
                                    (m) => m !== null && (m as any)['routeIdentifier'] === route,
                                );
                                items.filter((o) => o !== null).forEach((i) => (newSelection[i!.entityId] = i!));
                            } else newSelection[invoicing!.entityId] = invoicing!;
                        } else delete newSelection[invoicing!.entityId];

                        setRowSelection(newSelection);
                    }}
                />
                {(entryType.extraColumns ?? []).map((c) => (
                    <Typography key={'custom' + c.id} style={{ flex: 4 }}>
                        {((invoicing as any)[c.id]?.split('<br>') ?? []).map((s: string, index: number) => (
                            <span key={c.id + '-' + index}>
                                {index > 0 && <br />}
                                {s}
                            </span>
                        ))}
                    </Typography>
                ))}

                <Tooltip title={getDocNumberLabel(invoicing!)} key={'docnum-tooltip'}>
                    <Typography key={'docnum-label'} style={{ flex: 3 }}>
                        {getDocNumberLabel(invoicing!, 12)}
                    </Typography>
                </Tooltip>
                <Typography key={'customer-label'} style={{ flex: 6 }}>
                    {getCustomerLabel(invoicing!)}
                </Typography>
                <Typography key={'amount-label'} style={{ flex: 3 }}>
                    {getAmountLabel(invoicing!)}
                </Typography>
                <div key={'status-label'} style={{ flex: 3 }}>
                    {getStatusLabel(invoicing!)}
                </div>
                <Typography key={'last-operation-label'} style={{ flex: 4 }}>
                    {getLastOperationLabel(invoicing!).map((s: string, index: number) => (
                        <span key={'last-operation-label-' + index}>
                            {s}
                            <br />
                        </span>
                    ))}
                </Typography>
                <div style={{ display: 'flex', flex: 1 }}>
                    <Tooltip title={t('Reset')} key={'buttons-reset'}>
                        <span>
                            <IconButton
                                // disabled={invoicing.state === 'mapped' || !invoicingRefreshTrackerRef.current}
                                onClick={(event) => {
                                    confirmDialog(
                                        t('Reset {{invoicingType}}?', { invoicingType: t(entryType.label) }),
                                        t('Are you sure you want to reset this {{invoicingType}}?', {
                                            invoicingType: t(entryType.label),
                                        }),
                                        t('Yes'),
                                        t('No'),
                                        'warning',
                                        async () => {
                                            apiClient.resetInvoicing(
                                                entryType.class as InvoicingType,
                                                invoicing.entityId,
                                            );
                                            invoicingRefreshTrackerRef.current!.addInvoicing(invoicing);
                                            updateInvoicingStatus(invoicing, 'processing');
                                            setOpenSnackbarMessage(
                                                t('{{invoicingType}} has been reset', {
                                                    invoicingType: t(entryType.label),
                                                }) ?? '',
                                            );
                                        },
                                    );
                                }}
                            >
                                <ReplayIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title={t('Ignore/Hide {{invoicingType}}', { invoicingType: t(entryType.label) })}>
                        <span>
                            <IconButton
                                disabled={
                                    !(
                                        invoicing.state === 'pending-confirmation' ||
                                        invoicing.state === 'pending-mapping' ||
                                        invoicing.state === 'pending-invalidated' ||
                                        !invoicingRefreshTrackerRef.current
                                    )
                                }
                                onClick={(event) => {
                                    confirmDialog(
                                        t('Ignore {{invoicingType}}?', { invoicingType: t(entryType.label) }),
                                        t('Are you sure you want to ignore this {{invoicingType}}?', {
                                            invoicingType: t(entryType.label),
                                        }),
                                        t('Yes'),
                                        t('No'),
                                        'warning',
                                        () => {
                                            apiClient.ignoreInvoicing(entryType.class as InvoicingType, [
                                                invoicing.entityId,
                                            ]);
                                            invoicingRefreshTrackerRef.current!.addInvoicing(invoicing);
                                            updateInvoicingStatus(invoicing, 'processing');
                                            setOpenSnackbarMessage(
                                                t('{{invoicingType}} has been ignored/hidden', {
                                                    invoicingType: t(entryType.label),
                                                }) ?? '',
                                            );
                                        },
                                    );
                                }}
                            >
                                <VisibilityOffIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title={t('Ask for help')}>
                        <IconButton
                            onClick={(event) => {
                                openHelpDialog([invoicing.entityNumber], async (userQuery) => {
                                    const orderNumbersAndWorkflowIds = [
                                        invoicing.entityNumber +
                                            ' : ' +
                                            invoicing.integrationIdAndType +
                                            '_' +
                                            invoicing.entityId,
                                    ];
                                    const ticket: AtlassianTicket = {
                                        subject: "[PivoHub] - Demande d'aide / Request for help",
                                        orderNumbersAndWorkflowIds: orderNumbersAndWorkflowIds,
                                        userQuery: userQuery,
                                        userEmail: user?.email || user?.name,
                                    };
                                    const success = await apiClient.createAtlassianTicket(ticket);
                                    if (success)
                                        setOpenSnackbarMessage(
                                            t('Your query has been submitted. We will get back to you shortly.') ?? '',
                                        );
                                    else setOpenSnackbarMessage(t('An error occurred. Please try again.') ?? '');
                                });
                            }}
                        >
                            <HelpIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        );
    };

    if (!invoicings || !config) {
        return (
            <div
                style={{
                    height: '100vh',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <CircularProgress />
            </div>
        );
    }

    //infinite scroll related stuff
    const hasNextPage = invoicings.current[invoicings.current.length - 1] !== null;
    const itemCount = hasNextPage ? invoicings.current.length + 1 : invoicings.current.length;
    const loadMoreItems = isNextPageLoading ? () => {} : () => fetchMoreInvoicings(false);
    const isItemLoaded = (index: number) => !hasNextPage || index < invoicings.current.length;

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                }}
            >
                <Tooltip title={t('Refresh the {{invoicingtype}}s list')} key={'refresh-invoicings'}>
                    <Button
                        variant='contained'
                        size='small'
                        onClick={() => {
                            init();
                        }}
                    >
                        <RefreshIcon />
                    </Button>
                </Tooltip>
                <BulkActionsList
                    isDisabled={Object.keys(rowSelection).length === 0}
                    actions={[
                        { text: t('Transfer orders'), onClick: handleTransfer },
                        { text: t('Reset orders'), onClick: handleReset },
                        { text: t('Ask help'), onClick: handleHelp },
                    ]}
                />
                <Tooltip
                    title={t('Display only {{invoicingType}}s that require your attention.', {
                        invoicingType: t(entryType.label),
                    })}
                    key={'show-pending-only-checkbox'}
                    style={{ display: 'flex', width: '220px' }}
                >
                    <div>
                        <input type='checkbox' ref={showMissingsOnly} />
                        <div className='label'>{t('Pending')}</div>
                    </div>
                </Tooltip>

                {entryType.extraColumns?.find((c) => c.groupable) && (
                    <Tooltip
                        title={t(
                            'Only display {{invoicingType}}s with delivery routes and enable quick selection of those {{invoicingType}}s.',
                            { invoicingType: t(entryType.label) },
                        )}
                        key={'buttons-group-by-route'}
                    >
                        <div
                            key={'group-by-route-checkbox'}
                            style={{ display: 'flex', width: '350px', minWidth: 'fit-content' }}
                        >
                            <input ref={grouping} type='checkbox' />
                            <div className='label'>{t('Group by route')}</div>
                        </div>
                    </Tooltip>
                )}
                <TextField
                    fullWidth={true}
                    key={'search-field'}
                    style={{ marginLeft: '10px' }}
                    size='small'
                    value={filterByInvoicingNumber.current}
                    label={t('Search by {{invoicingType}} number', { invoicingType: t(entryType.label) })}
                    onChange={(event) => {
                        filterByInvoicingNumber.current = event.target.value;
                        init();
                    }}
                />

                <Tooltip
                    title={t(
                        'Manual mode requires that you select and transmit {{invoicingType}}s, while the automatic mode does it as soon as they are received.',
                        { invoicingType: t(entryType.label) },
                    )}
                    key={'buttons-manual-mode'}
                >
                    <ToggleButtonGroup
                        color='primary'
                        value={
                            entryType.class === 'ORDER' ? (mode?.order ? true : false) : mode?.payment ? true : false
                        }
                        exclusive
                        onChange={(e, newMode: boolean) => {
                            if (newMode != null && mode != null && newMode !== mode.order) {
                                confirmDialog(
                                    t('Change mode?'),
                                    `${t(`Are you sure you want to change mode to {{newMode}}?`, {
                                        newMode: newMode,
                                    })}
                                                    ${t('Manuel mode')}: ${t(
                                        ' waits until you click on transfert to send {{invoicingType}}s to {{software}}.',
                                        { invoicingType: t(entryType.label), software: config.type },
                                    )}
                                                    ${t('Automatic mode')}: ${t(
                                        ' sends {{invoicingType}}s to {{software}} as soon as they are identified.',
                                        { invoicingType: t(entryType.label), software: config.type },
                                    )}`,
                                    t('Yes'),
                                    t('No'),
                                    'warning',
                                    () => {
                                        if (entryType.class === 'ORDER') {
                                            setMode({ order: newMode });
                                            apiClient.saveConfig({
                                                autoMode: {
                                                    order: newMode,
                                                },
                                            });
                                            dispatch({
                                                type: ActionTypes.SAVE_CONFIG,
                                                payload: {
                                                    orderWorkflowConfigs: {
                                                        autoMode: {
                                                            order: newMode,
                                                        },
                                                    },
                                                },
                                            });
                                        } else {
                                            //TODO ...
                                            console.log('TODO ... pour payment');
                                        }
                                    },
                                );
                            }
                        }}
                    >
                        <ToggleButton key={'manual'} value={false}>
                            {t('manual')}
                        </ToggleButton>
                        <ToggleButton key={'automatic'} value={true}>
                            {t('automatic')}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Tooltip>
            </div>
            <br />
            <div key={'invoicing-div'} style={{ position: 'relative', height: '100vh' }}>
                <div style={{ position: 'sticky', top: 0, zIndex: 100 }}>
                    <Header />
                </div>
                <div style={{ overflow: 'auto', height: 'calc(100vh - 50px)' }}>
                    {!invoicings && (
                        <div
                            style={{
                                height: '100vh',
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <CircularProgress />
                        </div>
                    )}
                    <AutoSizer>
                        {({ height, width }) => (
                            <InfiniteLoader
                                isItemLoaded={isItemLoaded}
                                itemCount={itemCount}
                                loadMoreItems={loadMoreItems}
                            >
                                {({ onItemsRendered, ref }) => (
                                    <List
                                        height={height ?? 0}
                                        itemCount={invoicings.current.length}
                                        itemSize={55}
                                        width={width ?? 0}
                                        onItemsRendered={onItemsRendered}
                                        ref={ref}
                                    >
                                        {rowRenderer}
                                    </List>
                                )}
                            </InfiniteLoader>
                        )}
                    </AutoSizer>
                </div>
            </div>
            <Dialog open={!!blockingMapping} onClose={() => setBlockingMapping(undefined)}>
                <DialogTitle>{t('Missing mappings')}</DialogTitle>
                <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('Type')}</TableCell>
                            <TableCell>{t('Identifier')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {blockingMapping &&
                            blockingMapping.map((mapping) => (
                                <TableRow
                                    key={mapping.entityId}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component='th' scope='row'>
                                        {t(titleCase(mapping.integrationIdAndType?.split('_')?.[1]))}
                                    </TableCell>
                                    <TableCell>{mapping.label}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                <DialogActions>
                    <Button onClick={() => setBlockingMapping(undefined)} autoFocus>
                        {t('Close')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(openSnackbarMessage)}
                autoHideDuration={2000}
                onClose={() => setOpenSnackbarMessage(undefined)}
                message={t(openSnackbarMessage ?? '')}
            />
            <HelpDialog />
        </div>
    );
}
