import { Typography } from '@mui/material';
import { Container } from '@mui/system';
import { useTranslation } from 'react-i18next';

const MaintenancePage = ({ to }: { to?: Date }) => {
    const { t } = useTranslation();

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
            }}
        >
            <Container>
                <Typography variant='h4' component='h1' style={{ marginBottom: '10px' }}>
                    Nous reviendrons bientôt!
                </Typography>
                <Typography variant='subtitle1' component='p' style={{ marginBottom: '10px' }}>
                    ProgyLink fait actuellement l'objet d'une maintenance planifiée. Nous vous prions de bien vouloir
                    nous excuser pour la gêne occasionnée et de réessayer plus tard.
                </Typography>
                {to !== undefined && (
                    <Typography variant='subtitle1' component='p' noWrap style={{ marginBottom: '10px' }}>
                        Délai d'exécution estimé : <strong>{to.toString()}</strong>
                    </Typography>
                )}
                <Typography variant='subtitle1' component='p' style={{ marginBottom: '10px' }}>
                    En attendant, vous pouvez nous joindre par <a href={`mailto:support@progi-media.com`}>courriel</a>
                </Typography>
            </Container>
            <Container>
                <Typography variant='h4' component='h1' style={{ marginBottom: '10px' }}>
                    We'll be back soon!
                </Typography>
                <Typography variant='subtitle1' component='p' style={{ marginBottom: '10px' }}>
                    ProgyLink is currently undergoing scheduled maintenance. We apologize for the inconvenience and ask
                    that you please try again later.
                </Typography>
                {to !== undefined && (
                    <Typography variant='subtitle1' component='p' noWrap style={{ marginBottom: '10px' }}>
                        Estimated Time of Completion: <strong>{to.toString()}</strong>
                    </Typography>
                )}
                <Typography variant='subtitle1' component='p' style={{ marginBottom: '10px' }}>
                    In the meantime, you can reach us by <a href={`mailto:support@progi-media.com`}>email</a>
                </Typography>
            </Container>
        </div>
    );
};

export default MaintenancePage;
