export enum ActionTypes {
    SET_STATE_NO_MAPPINGS = 'SET_STATE_NO_MAPPINGS',
    SET_MAPPINGS = 'SET_MAPPINGS',
    SAVE_CONFIG = 'SAVE_CONFIG',
    SELECT_INTEGRATION_TYPE = 'SELECT_INTEGRATION_TYPE',
    SAVE_MAPPING = 'SAVE_MAPPING',
    UPDATE_MAPPING_VALUES = 'UPDATE_MAPPING_VALUES',
    UPDATE_IS_CONNECTED = 'UPDATE_IS_CONNECTED',
    SET_CONFIG = 'SET_CONFIG',
    ADD_MISSING_MAPPING = 'ADD_MISSING_MAPPING',
    SET_MAINTENANCE = 'SET_MAINTENANCE',
    SET_MAPPINGS_BY_TYPE = 'SET_MAPPINGS_BY_TYPE',
}

export type SetStateNoMappingsAction = {
    type: ActionTypes.SET_STATE_NO_MAPPINGS;
    payload: {
        config?: WebAppConfig;
        maintenance: MaintenanceType;
        isConnected: boolean;
    };
};

export type SetConfigAction = {
    type: ActionTypes.SET_CONFIG;
    payload: {
        config: WebAppConfig;
    };
};

export type SetMappingsAction = {
    type: ActionTypes.SET_MAPPINGS;
    payload: {
        mappings?: { [key: string]: Mapping[] };
        mappingValues?: { [key: string]: MappingValue[] };
    };
};

export type UpdateMappingValuesAction = {
    type: ActionTypes.UPDATE_MAPPING_VALUES;
    payload: {
        type: string;
        mappingValues: MappingValue[];
    };
};

export type SaveConfigAction = {
    type: ActionTypes.SAVE_CONFIG;
    payload: Partial<WebAppConfig['orderWorkflowConfigs']>;
};

export type SelectIntegrationType = {
    type: ActionTypes.SELECT_INTEGRATION_TYPE;
    payload: { integrationType: IntegrationTypes };
};

export type SaveMappingAction = {
    type: ActionTypes.SAVE_MAPPING;
    payload: { entityId: string; class: WebappEntryType['class']; externalEntityId: string };
};

export type UpdateIsConnectedAction = {
    type: ActionTypes.UPDATE_IS_CONNECTED;
    payload: { isConnected: boolean };
};

export type AddMissingMappingAction = {
    type: ActionTypes.ADD_MISSING_MAPPING;
    payload: { mappings: Mapping[] };
};

export type SetMaintenanceAction = {
    type: ActionTypes.SET_MAINTENANCE;
    payload: { maintenance: MaintenanceType };
};

export type SetMappingsByTypeAction = {
    type: ActionTypes.SET_MAPPINGS_BY_TYPE;
    payload: { class: WebappEntryType['class']; mappings: Mapping[] };
};

export type Action =
    | SetStateNoMappingsAction
    | SaveConfigAction
    | SelectIntegrationType
    | SaveMappingAction
    | UpdateMappingValuesAction
    | UpdateIsConnectedAction
    | SetMappingsAction
    | SetConfigAction
    | AddMissingMappingAction
    | SetMaintenanceAction
    | SetMappingsByTypeAction;

import {
    IntegrationTypes,
    MaintenanceType,
    Mapping,
    MappingValue,
    WebAppConfig,
    WebappEntryType,
} from '@progyconnect/webapp-types';
