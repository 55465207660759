import { Alert, Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { create } from 'zustand';

type ConfirmDialogStore = {
    title: string;
    message: string;
    okButton: string;
    cancelButton: string;
    severity: 'info' | 'error' | 'warning' | 'success';
    onSubmit?: () => void;
    close: () => void;
};

const useConfirmDialogStore = create<ConfirmDialogStore>((set) => ({
    title: '',
    message: '',
    okButton: '',
    cancelButton: '',
    onSubmit: undefined,
    severity: 'error',
    close: () => set({ onSubmit: undefined }),
}));

export const confirmDialog = (
    title: string,
    message: string,
    okButton: string,
    cancelButton: string,
    severity: 'info' | 'error' | 'warning' | 'success',
    onSubmit: () => void,
) => {
    useConfirmDialogStore.setState({
        title,
        message,
        okButton,
        cancelButton,
        severity,
        onSubmit,
    });
};

const ConfirmDialog = () => {
    const { title, message, okButton, cancelButton, severity, onSubmit, close } = useConfirmDialogStore();
    return (
        <Dialog open={Boolean(onSubmit)} onClose={close} maxWidth='sm' fullWidth>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <DialogTitle>{title}</DialogTitle>
                <IconButton onClick={close}>
                    <Close />
                </IconButton>
            </div>
            <DialogContent>
                <Alert severity={severity}>{message}</Alert>
            </DialogContent>
            <DialogActions>
                <Button color='primary' variant='contained' onClick={close}>
                    {cancelButton}
                </Button>
                <Button
                    color='secondary'
                    variant='contained'
                    onClick={() => {
                        if (onSubmit) {
                            onSubmit();
                        }
                        close();
                    }}
                >
                    {okButton}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
