import { Button } from '@mui/material';
import type { WebAppConfig } from '@progyconnect/webapp-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useIntegration, IntegrationContextType } from '../../contexts/IntegrationProvider';
import { Action, ActionTypes } from '../../redux/Action';

const WizardConfigure = () => {
    const { t } = useTranslation();
    const { apiClient } = useIntegration() as IntegrationContextType;
    const dispatch = useDispatch<Dispatch<Action>>();

    return (
        <div>
            <div
                style={{
                    height: '100vh',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                        {t('Connection successfull!')}
                    </div>
                    <Button
                        variant='contained'
                        onClick={() => {
                            apiClient.saveConfig({
                                status: 'active',
                            });
                            dispatch({
                                type: ActionTypes.SAVE_CONFIG,
                                payload: {
                                    status: 'active',
                                },
                            });

                            apiClient.finishSetup();
                        }}
                        style={{ marginBottom: '20px' }}
                    >
                        {t('Activate integration')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default WizardConfigure;
