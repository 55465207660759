import React, { useState } from 'react';
import { List, ListItem, ListItemButton, ListItemText, Popover } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
interface ActionButton {
    text: string;
    onClick: () => void;
    tooltip?: string | null;
}

interface BulkActionsListItemProps {
    isDisabled: boolean;
    actions: ActionButton[];
}

const BulkActionsList: React.FC<BulkActionsListItemProps> = ({ isDisabled, actions }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const handleClickListItem = (event: React.MouseEvent<HTMLDivElement>) => {
        if (!isDisabled) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <List component='nav' style={{ minWidth: 'max-content' }}>
            <ListItem disabled={isDisabled}>
                <ListItemButton onClick={handleClickListItem}>
                    <ListItemText primary={t('Bulk actions')} />
                    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
            </ListItem>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <List component='nav'>
                    {actions.map((action, index) => (
                        <Tooltip
                            key={index}
                            title={action.tooltip || ''}
                            placement='left'
                            disableHoverListener={!action.tooltip}
                        >
                            <ListItem button onClick={action.onClick}>
                                <ListItemText primary={t(action.text)} />
                            </ListItem>
                        </Tooltip>
                    ))}
                </List>
            </Popover>
        </List>
    );
};

export default BulkActionsList;
