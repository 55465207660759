import { Autocomplete, TextField } from '@mui/material';
import { Integration } from '@progyconnect/webapp-types';
import { IntegrationContextType, useIntegration } from '../contexts/IntegrationProvider';

export const IntegrationsCombo = () => {
    const { integrations, integrationId, setIntegrationId } = useIntegration() as IntegrationContextType;

    return !integrations ? null : (
        <Autocomplete
            disabled={!integrations}
            size='small'
            fullWidth={true}
            disableClearable={true}
            multiple={false}
            options={integrations}
            renderInput={(params) => <TextField {...params} />}
            getOptionLabel={(v) => v['name']}
            value={integrations ? integrations.find((i) => i.id === integrationId) : undefined}
            onChange={(event, integration: Integration) => {
                if (integration && integration.id !== integrationId) setIntegrationId(integration.id);
            }}
        />
    );
};
