import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';

import { useIdentity } from './IdentityProvider';
import { ApiClient } from '../ApiClient';
import { useLocalStorage } from 'usehooks-ts';
import { Integration } from '@progyconnect/webapp-types';

export type IntegrationContextType = {
    integrationId: string | undefined;
    setIntegrationId: (integrationId: string) => void;
    apiClient: ApiClient;
    integrations: Integration[] | undefined;
    setIntegrations: (integrations: Integration[]) => void;
};

const IntegrationContext = createContext<IntegrationContextType | undefined>(undefined);

export function useIntegration() {
    return useContext(IntegrationContext);
}

export default function IntegrationProvider({ children }: { children: ReactNode }) {
    const [integrationId, setIntegrationIdInternal] = useLocalStorage('integrationId', '');
    const [integrations, setIntegrations] = useState<Integration[]>();
    const { token } = useIdentity();
    const [apiClient] = useState<ApiClient>(new ApiClient());

    const setIntegrationId = useCallback(
        (integrationId: string) => {
            setIntegrationIdInternal(integrationId);
            apiClient.setIntegrationId(integrationId);
        },
        [setIntegrationIdInternal],
    );

    const [fullyConfigured, setFullyConfigured] = useState(false);

    useEffect(() => {
        if (!fullyConfigured && token) {
            apiClient.configure(token);

            if (integrationId) apiClient.setIntegrationId(integrationId);

            const debugIntegrationId = import.meta.env.VITE_INTEGRATION_ID;
            if (debugIntegrationId) {
                setIntegrationIdInternal(debugIntegrationId);
            } else {
                apiClient.getMyIntegrations().then((data) => {
                    setIntegrations(data);
                    if (!integrationId || data.find((i: Integration) => i.id === integrationId) === undefined)
                        setIntegrationId(data[0].id);
                });
            }

            if (token && integrationId) setFullyConfigured(true);
        }
    }, [apiClient, token, integrationId, setIntegrations, setIntegrationId, setIntegrationIdInternal]);

    return (
        <IntegrationContext.Provider
            value={{
                integrationId,
                setIntegrationId,
                apiClient,
                integrations,
                setIntegrations,
            }}
        >
            {children}
        </IntegrationContext.Provider>
    );
}
