import { Typography, FormControlLabel, Checkbox, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useState } from 'react';

const WizardPoliciesEN = ({ handleContinueClick }: { handleContinueClick?: () => void }) => {
    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(event.target.checked);
    };
    return (
        <>
            <Typography variant='h4'>Privacy Policy</Typography>
            <Typography variant='body1'>
                At ProgyMédia, we respect your privacy and are committed to protecting the personal information you
                provide to us. We do not share your information with third parties and only use this information to
                ensure the proper functioning and improvement of our software.
            </Typography>
            <Typography variant='h5' style={{ marginTop: '10px' }}>
                Information we collect
            </Typography>
            <Typography variant='body1'>
                We collect information such as your username, email address and account preferences when you sign up for
                ProgyLink. We may also collect information about your use of our software, such as the orders you
                transfer and the products associated with them.
            </Typography>
            <Typography variant='h5' style={{ marginTop: '10px' }}>
                Use of information
            </Typography>
            <Typography variant='body1'>
                We use the information we collect to improve our software and provide you with a better user experience.
                We do not share your information with third parties unless required by law or expressly authorized by
                you.
            </Typography>
            <Typography variant='h5' style={{ marginTop: '10px' }}>
                Information security
            </Typography>
            <Typography variant='body1'>
                We take appropriate security measures to protect the information you provide to us from loss, misuse and
                unauthorized access. We store information on secure servers and use encryption protocols to protect
                communications.
            </Typography>
            <Typography variant='h5' style={{ marginTop: '10px' }}>
                Information retention
            </Typography>
            <Typography variant='body1'>
                We only keep your information for as long as necessary to provide our service and to comply with our
                legal obligations. If you wish to remove your information from our system, please contact us at the
                email address below.
            </Typography>
            <Typography variant='h5' style={{ marginTop: '10px' }}>
                Contact us
            </Typography>
            <Typography variant='body1'>
                If you have any questions or concerns about our privacy policy, or if you would like to remove your
                information from our system, please contact us at the following email address:`;
            </Typography>
            <Typography variant='h4' style={{ marginTop: '20px' }}>
                Terms of Use
            </Typography>
            <Typography variant='h5' style={{ marginTop: '10px' }}>
                Acceptance of Terms of Use
            </Typography>
            <Typography variant='body1'>
                By using the ProgyLink application (hereinafter "the Application"), you agree to these terms of use
                ("Terms"). If you do not agree to these Terms, please do not use the Application.
            </Typography>
            <Typography variant='h5' style={{ marginTop: '10px' }}>
                App Description
            </Typography>
            <Typography variant='body1'>
                ProgyLink is an application that allows users to connect with accounting and ERP software to
                automatically transfer various documents such as orders and invoices. ProgyLink is the property of
                ProgyMédia inc. (hereinafter "ProgyMedia").
            </Typography>
            <Typography variant='h5' style={{ marginTop: '10px' }}>
                Using the app
            </Typography>
            <Typography variant='body1'>Use of the Application is subject to the following conditions:</Typography>
            <Typography variant='body1' component='ul' sx={{ m: 2, p: 0 }}>
                <li>
                    You may not use the Application for any illegal purpose or to cause damage to other users or to the
                    Application itself.
                </li>
                <li> You are responsible for all activity that occurs under your account.</li>
                <li>
                    The Application and all its content, including but not limited to logos, texts, images, videos,
                    files, software and codes, are protected by intellectual property laws. You may not use, reproduce,
                    distribute or modify the content of the Application without the prior written authorization of
                    ProgyMédia.
                </li>
            </Typography>
            <Typography variant='h5' style={{ marginTop: '10px' }}>
                Privacy
            </Typography>
            <Typography variant='body1'>
                ProgyMédia is committed to respecting your privacy and protecting the personal information you provide
                when using the Application. Please see our Privacy Policy for more information on how we collect, use
                and protect your data.
            </Typography>
            <Typography variant='h5' style={{ marginTop: '10px' }}>
                Changes to Terms of Service
            </Typography>
            <Typography variant='body1'>
                ProgyMédia reserves the right to modify these conditions of use at any time. Any modifications will be
                published on the Application and you will be informed of these modifications by e-mail or any other
                appropriate means of communication. Your continued use of the Application following the posting of
                changes constitutes your acceptance of those changes.
            </Typography>
            <Typography variant='h5' style={{ marginTop: '10px' }}>
                Termination
            </Typography>
            <Typography variant='body1'>
                ProgyMédia may terminate your right of access to the Application at any time if you violate these terms
                of use.
            </Typography>
            <Typography variant='h5' style={{ marginTop: '10px' }}>
                Limitation of Liability
            </Typography>
            <Typography variant='body1' style={{ marginBottom: '15px' }}>
                ProgyMédia cannot under any circumstances be held liable for any direct or indirect damage resulting
                from the use or inability to use the Application, including loss of data, service interruptions, errors,
                omissions, viruses, delays or disruptions in operations.
            </Typography>
            {handleContinueClick && (
                <>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                                icon={<CheckCircleOutlineIcon />}
                                checkedIcon={<CheckCircleOutlineIcon sx={{ color: 'primary.main' }} />}
                                color='primary'
                            />
                        }
                        label='I agree to the terms of use and privacy policy.'
                    />
                    <Button variant='contained' color='primary' onClick={handleContinueClick} disabled={!isChecked}>
                        Continue
                    </Button>
                </>
            )}
        </>
    );
};

export default WizardPoliciesEN;
