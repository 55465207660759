import type {
    AgentConfigAndState,
    AuthenticationProviderConfiguration,
    ColumnFilter,
    DynamoKey,
    DynamoPagedResponse,
    Integration,
    IntegrationTypes,
    Invoicing,
    InvoicingType,
    MaintenanceType,
    Mapping,
    MappingDTO,
    MappingType,
    MappingValue,
    MappingValueAndType,
    WebAppConfig,
    WebappEntryType,
} from '@progyconnect/webapp-types';

import { AtlassianTicket } from '@progyconnect/webapp-types';

export class ApiClient {
    token: string | undefined;
    integrationId: string | undefined;
    fetchConfigs: { [k: string]: RequestInit } = {};
    isReady: boolean = false;

    configure(token: string) {
        this.token = token;
        this.fetchConfigs = {
            GET: {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + token!,
                    'Content-Type': 'application/json',
                },
                cache: 'no-store',
            },
            POST: {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + token!,
                    'Content-Type': 'application/json',
                },
            },
        };

        if (this.integrationId) this.isReady = true;
    }

    setIntegrationId(integrationId: string) {
        this.integrationId = integrationId;

        if (this.token) this.isReady = true;
    }

    async getAuthenticationProviderConfiguration(): Promise<AuthenticationProviderConfiguration> {
        const response = await fetch(`/api/v1/authConfig`, {
            method: 'GET',
        });

        return await response.json();
    }

    async getMappings(type: WebappEntryType, lang: 'EN' | 'FR'): Promise<Mapping[]> {
        const queryParams: string[] = [];

        queryParams.push(`lang=${lang}`);
        queryParams.push(`limit=ALL`);

        let query = '';
        if (queryParams.length > 0) {
            query = '?' + queryParams[0];
            for (let i = 1; i < queryParams.length; i++) {
                query += '&' + queryParams[i];
            }
        }

        await this.waitUntilReady();
        console.log('getMapping', type.class);
        console.log('query', query);
        console.log('url', `/api/v1/${this.integrationId}/mappings/${type.class}${query}`);
        const response = await fetch(
            `/api/v1/${this.integrationId}/mappings/${encodeURIComponent(type.class)}${query}`,
            this.fetchConfigs.GET,
        );

        const body = await response.text();

        if (!body || body === '' || body === 'null') return [];

        return (await JSON.parse(body)?.items) ?? [];
    }

    async getInvoicingsByIds(type: InvoicingType, ids: string[]): Promise<Invoicing[]> {
        await this.waitUntilReady();

        const response = await fetch(`/api/v1/${this.integrationId}/mappings-by-ids/${type}`, {
            ...this.fetchConfigs.POST,
            body: JSON.stringify(ids),
        });

        const body = await response.text();

        if (!body || body === '') return [];
        return await JSON.parse(body);
    }

    async getInvoicings(
        invoicingType: InvoicingType,
        limit?: number,
        lastEvaluatedKey?: DynamoKey,
        columnFilter?: ColumnFilter[],
        grouping?: string[],
    ): Promise<DynamoPagedResponse<Invoicing>> {
        const queryParams: string[] = [];

        if (limit) queryParams.push(`limit=${encodeURIComponent(limit)}`);

        if (lastEvaluatedKey)
            queryParams.push(`lastEvaluatedKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`);

        if (columnFilter) {
            for (let i = 0; i < columnFilter.length; i++) {
                const filter = columnFilter[i];
                queryParams.push(`${encodeURIComponent(filter.id)}=${encodeURIComponent(`${filter.value}`)}`);
            }
        }

        if (grouping) {
            for (let i = 0; i < grouping.length; i++) {
                const group = grouping[i];
                queryParams.push(`group=${encodeURIComponent(group)}`);
            }
        }

        let query = '';
        if (queryParams.length > 0) {
            query = '?' + queryParams[0];
            for (let i = 1; i < queryParams.length; i++) {
                query += '&' + queryParams[i];
            }
        }

        await this.waitUntilReady();

        const response = await fetch(
            `/api/v1/${this.integrationId}/mappings/${invoicingType}${query}`,
            this.fetchConfigs.GET,
        );

        if (response.status !== 200)
            return {
                items: [],
                LastEvaluatedKey: undefined,
            };

        const body = await response.text();

        if (!body || body === '' || body === 'null')
            return {
                items: [],
                LastEvaluatedKey: undefined,
            };

        return await JSON.parse(body);
    }

    async getMappingValues(type: string, refresh?: boolean): Promise<MappingValueAndType[]> {
        await this.waitUntilReady();
        console.log('type', type);
        const response = await fetch(
            `/api/v1/${this.integrationId}/mappingValues/${type.toUpperCase()}${refresh ? '?refresh=true' : ''}`,
            this.fetchConfigs.GET,
        );

        if (response.status === 404) return [];

        const txt = await response.text();

        if (!txt || txt === '' || txt === 'null') return [];

        const values: MappingValue[] = await JSON.parse(txt);

        return values
            .filter((value, index, self) => {
                return self.indexOf(value) === index;
            })
            .map((value) => {
                return {
                    ...value,
                    type: type,
                };
            });
    }

    async saveMappings(newMappings: MappingDTO[]): Promise<boolean> {
        await this.waitUntilReady();

        const response = await fetch(`/api/v1/${this.integrationId}/mappings`, {
            ...this.fetchConfigs.POST,
            body: JSON.stringify(newMappings),
        });

        return response.status === 200;
    }

    async resetMapping(mappings: MappingDTO[]): Promise<boolean> {
        await this.waitUntilReady();

        const response = await fetch(`/api/v1/${this.integrationId}/invalidMappings`, {
            ...this.fetchConfigs.POST,
            body: JSON.stringify(mappings),
        });

        return response.status === 200;
    }

    async createAtlassianTicket(ticket: AtlassianTicket): Promise<boolean> {
        await this.waitUntilReady();

        const response = await fetch(`/api/v1/${this.integrationId}/atlassian/ticket`, {
            ...this.fetchConfigs.POST,
            body: JSON.stringify(ticket),
        });

        return response.status === 200;
    }


    async createCustomer(mapping: Mapping): Promise<void> {
        const integrationId = this.integrationId;
        const entityId = mapping.entityId;

        if (!integrationId) throw new Error('Integration ID is not set.');

        const response = await fetch(`/api/v1/${integrationId}/createCustomer/${entityId}`, {
            ...this.fetchConfigs.POST,
        });

        if (response.status !== 200) {
            throw new Error('Error creating customer');
        }
    }

    async getMissingsCount(): Promise<{ [key: string]: number }> {
        await this.waitUntilReady();

        return (await fetch(`/api/v1/${this.integrationId}/missings`, this.fetchConfigs.GET))?.json();
    }

    async getMappingsCount(type: string): Promise<number> {
        await this.waitUntilReady();

        return await (
            await fetch(`/api/v1/${this.integrationId}/mappings/${type}/count`, this.fetchConfigs.GET)
        )?.json();
    }

    async isConnected(): Promise<boolean> {
        await this.waitUntilReady();

        return (
            (await (await fetch(`/api/v1/${this.integrationId}/isConnected`, this.fetchConfigs.GET))?.json())
                ?.isConnected ?? false
        );
    }

    async transmitInvoicing(invoicingType: InvoicingType, entityIds: string[]): Promise<void> {
        await this.waitUntilReady();

        for (const entityId of entityIds) {
            await fetch(
                `/api/v1/${this.integrationId}/invoicing/${invoicingType}/${entityId}/transmit`,
                this.fetchConfigs.GET,
            );
        }
    }
    async ignoreInvoicing(invoicingType: InvoicingType, entityIds: string[]): Promise<void> {
        await this.waitUntilReady();

        for (const entityId of entityIds) {
            await fetch(
                `/api/v1/${this.integrationId}/invoicing/${invoicingType}/${entityId}/ignore`,
                this.fetchConfigs.GET,
            );
        }
    }
    async blockingMapping(invoicingType: InvoicingType, entityId: string, lang: 'EN' | 'FR'): Promise<Mapping[]> {
        await this.waitUntilReady();

        const blockedMapping = await (
            await fetch(
                `/api/v1/${this.integrationId}/invoicing/${invoicingType}/${entityId}/block?lang=${lang}`,
                this.fetchConfigs.GET,
            )
        ).json();
        return blockedMapping;
    }
    async resetInvoicing(invoicingType: InvoicingType, entityId: string): Promise<void> {
        await this.waitUntilReady();

        await fetch(
            `/api/v1/${this.integrationId}/invoicing/${invoicingType}/${entityId}/retry`,
            this.fetchConfigs.GET,
        );
    }

    async invoicingMapping(invoicingType: InvoicingType, entityId: string, lang: 'EN' | 'FR'): Promise<Mapping[]> {
        await this.waitUntilReady();

        const dependentMappings = await (
            await fetch(
                `/api/v1/${this.integrationId}/invoicing/${invoicingType}/${entityId}/mappings?lang=${lang}`,
                this.fetchConfigs.GET,
            )
        ).json();
        return dependentMappings;
    }
    async getQBOLoginUrl(callback: string): Promise<string> {
        await this.waitUntilReady();

        return (
            await (
                await fetch(
                    `/api/v1/${this.integrationId}/initQBOAuth?callback=${encodeURIComponent(callback)}`,
                    this.fetchConfigs.GET,
                )
            )?.json()
        )?.url;
    }

    async getConfig(): Promise<WebAppConfig | undefined> {
        await this.waitUntilReady();

        const response = await fetch(`/api/v1/${this.integrationId}/config`, this.fetchConfigs.GET);

        const body = await response.text();

        if (!body) return undefined;

        return JSON.parse(body);
    }

    async getMaintenanceInfo(): Promise<MaintenanceType> {
        await this.waitUntilReady();

        return (await (await fetch(`/api/v1/maintenance`, this.fetchConfigs.GET))?.json()) as MaintenanceType;
    }

    async saveConfig(config: Partial<WebAppConfig['orderWorkflowConfigs']>): Promise<void> {
        await this.waitUntilReady();
        console.log('config save:', JSON.stringify(config));

        await fetch(`/api/v1/${this.integrationId}/config`, {
            ...this.fetchConfigs.POST,
            body: JSON.stringify(config),
        });
    }

    async selectIntegrationType(integrationType: IntegrationTypes): Promise<void> {
        await this.waitUntilReady();

        await fetch(`/api/v1/${this.integrationId}/selectedIntegrationType`, {
            ...this.fetchConfigs.POST,
            body: JSON.stringify({ workflowType: integrationType }),
        });
    }

    async reloadDetails(type: string, pivoEntityIds: string[]): Promise<void> {
        await this.waitUntilReady();

        await fetch(`/api/v1/${this.integrationId}/reloadDetails/${type}`, {
            ...this.fetchConfigs.POST,
            body: JSON.stringify(pivoEntityIds),
        });
    }

    //Acomba only
    async checkAgentVersion(config: WebAppConfig | undefined): Promise<boolean> {
        await this.waitUntilReady();
        if (!config) return false;

        const type = config.type as IntegrationTypes;
        if (type !== 'Qbo') {
            try {
                const response = await fetch(`/api/v1/${this.integrationId}/agentVersion`, this.fetchConfigs.GET);
                const data = await response.json();
                return data?.isUpToDate ?? false;
            } catch (error) {
                console.error('Erreur lors de la récupération de la version de l’agent :', error);
                return false;
            }
        }

        return true;
    }

    async finishSetup(): Promise<void> {
        await this.waitUntilReady();
        await fetch(`/api/v1/${this.integrationId}/finishSetup`, this.fetchConfigs.GET);
    }

    async getAgentConfiguration(): Promise<AgentConfigAndState> {
        await this.waitUntilReady();

        return await (await fetch(`/api/v1/${this.integrationId}/agentConfig`, this.fetchConfigs.GET)).json();
    }

    async getAgentDownloadUrl(): Promise<string> {
        await this.waitUntilReady();

        return (await fetch(`/api/v1/${this.integrationId}/agentDownloadUrl`, this.fetchConfigs.GET)).text();
    }

    async getAgentStatus(config: WebAppConfig | undefined): Promise<boolean> {
        await this.waitUntilReady();
        if (!config) return false;

        const type = config.type as IntegrationTypes;
        if (type !== 'Qbo') {
            try {
                const response = await fetch(`/api/v1/${this.integrationId}/agentStatus`, this.fetchConfigs.GET);
                const data = await response.json();
                return data?.isRunning ?? false;
            } catch (error) {
                console.error('Erreur lors de la récupération du statut de l’agent :', error);
                return false;
            }
        }

        return true;
    }

    async getMyIntegrations(): Promise<Integration[]> {
        return (await (await fetch(`/api/v1/myIntegrations`, this.fetchConfigs.GET)).json()) as Integration[];
    }

    async waitUntilReady() {
        while (!this.isReady) {
            await new Promise((resolve) => setTimeout(resolve, 100));
        }
    }
}
