import { Button } from '@mui/material';
import type { IntegrationTypes } from '@progyconnect/webapp-types';
import { CSSProperties } from 'react';
import { confirmDialog } from '../ConfirmDialog';
import { useTranslation } from 'react-i18next';

type SoftwareButtonProps = {
    logo: string;
    type: IntegrationTypes;
    style: CSSProperties;
    selectSoftware: (type: IntegrationTypes) => void;
};

const SoftwareButton = ({ logo, type, style, selectSoftware }: SoftwareButtonProps) => {
    const { t } = useTranslation();

    return (
        <Button
            variant='outlined'
            style={{
                width: '500px',
                height: '300px',
                margin: '30px',
            }}
            onClick={() => {
                confirmDialog(
                    t(`Are you sure you want to select {{type}} ?`, { type: type }),
                    t(`You are about to select {{type}} as your accounting software. This is not reversible.`, {
                        type: type,
                    }),
                    t(`Yes, select {{type}}`, { type: type }),
                    t('No'),
                    'warning',
                    () => {
                        selectSoftware(type);
                    },
                );
            }}
        >
            <img style={style} src={logo} alt='' />
        </Button>
    );
};

export default SoftwareButton;
