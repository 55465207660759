import App from './App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import IdentityProvider from './contexts/IdentityProvider';
import IntegrationProvider from './contexts/IntegrationProvider';
import { BrowserRouter } from 'react-router-dom';
import LocalizationContext from './contexts/LocalizationContext';
import AuthenticationProvider from './contexts/AuthenticationProvider';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { reducer } from './redux/Reducer';
import { State } from './redux/state';
import { Action } from './redux/Action';
import ConfirmDialog from './components/ConfirmDialog';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const store = configureStore<State, Action, any, any>({
    reducer,
});

root.render(
    <BrowserRouter>
        <Provider store={store}>
            <AuthenticationProvider>
                <IdentityProvider>
                    <IntegrationProvider>
                        <LocalizationContext>
                            <App />
                            <ConfirmDialog />
                        </LocalizationContext>
                    </IntegrationProvider>
                </IdentityProvider>
            </AuthenticationProvider>
        </Provider>
    </BrowserRouter>,
);
