import { Invoicing } from '@progyconnect/webapp-types';

export type RefreshCallback = (completedItems: Invoicing[]) => void;
export type GetUpdatedInvoicingsFunction = (invoicings: Invoicing[]) => Promise<Invoicing[]> | undefined;

export class InvoicingRefreshTracker {
    private trackedInvoicings: Invoicing[] = [];
    private refreshIntervalId: NodeJS.Timeout | undefined;
    private refreshCallback: RefreshCallback;
    private updateInvoicingsStatusCallback: GetUpdatedInvoicingsFunction;

    constructor(callback: RefreshCallback, getUpdatedInvoicings: GetUpdatedInvoicingsFunction) {
        this.refreshCallback = callback;
        this.updateInvoicingsStatusCallback = getUpdatedInvoicings;
    }

    public clear() {
        this.trackedInvoicings = [];
        this.stopRefreshInterval();
    }

    public addInvoicing(item: Invoicing): void {
        if (this.trackedInvoicings.find((invoicing) => invoicing.entityId === item.entityId) === undefined) {
            this.trackedInvoicings.push(item);
            this.startRefreshInterval();
        }
    }

    public stop() {
        this.stopRefreshInterval();
    }

    private startRefreshInterval(): void {
        if (this.refreshIntervalId) {
            return;
        }

        this.refreshIntervalId = setInterval(async () => {
            await this.refreshInvoicings();
        }, 10000);
    }

    private stopRefreshInterval(): void {
        if (this.refreshIntervalId) {
            clearInterval(this.refreshIntervalId);
            this.refreshIntervalId = undefined;
        }
    }

    private async refreshInvoicings(): Promise<void> {
        const chunkedInvoicings: Invoicing[][] = chunkArray(this.trackedInvoicings, 100);
        const completedInvoicings: Invoicing[] = [];

        for (let i = 0; i < chunkedInvoicings.length; i++) {
            const updatedInvoicings: Invoicing[] = this.extractUpdatedInvoicings(
                this.trackedInvoicings,
                await this.updateInvoicingsStatusCallback(chunkedInvoicings[i]),
            );

            for (let j = 0; j < updatedInvoicings.length; j++) {
                const updatedInvoicing = updatedInvoicings[j];
                const index = this.trackedInvoicings.findIndex(
                    (trackedInvoicing) => trackedInvoicing.entityId === updatedInvoicing.entityId,
                );

                if (index !== -1) {
                    completedInvoicings.push(updatedInvoicing);
                    this.trackedInvoicings.splice(index, 1);
                }
            }
        }

        if (completedInvoicings.length > 0 && this.refreshCallback) {
            this.refreshCallback(completedInvoicings);
        }

        if (this.trackedInvoicings.length === 0) {
            this.stopRefreshInterval();
        }
    }

    private extractUpdatedInvoicings(
        trackedInvoicings: Invoicing[],
        updatedInvoicings: Invoicing[] | undefined,
    ): Invoicing[] {
        const updatedInvoicingsMap: { [entityId: string]: Invoicing } = {};

        if (!updatedInvoicings) return [];

        for (const updatedInvoicing of updatedInvoicings) {
            updatedInvoicingsMap[updatedInvoicing.entityId] = updatedInvoicing;
        }

        const result: Invoicing[] = [];

        for (const trackedInvoicing of trackedInvoicings) {
            const updatedInvoicing = updatedInvoicingsMap[trackedInvoicing.entityId];

            if (
                updatedInvoicing &&
                updatedInvoicing.lastUpdateDate > trackedInvoicing.lastUpdateDate &&
                updatedInvoicing.state !== 'processing' &&
                updatedInvoicing.state !== 'pending-mapping'
            ) {
                result.push(updatedInvoicing);
            }
        }

        return result;
    }
}

function chunkArray<T>(array: T[], chunkSize: number): T[][] {
    const chunks: T[][] = [];

    for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize));
    }

    return chunks;
}
