import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ConfigureAcomba from './configWizard/ConfigureAcomba';

export const ConnectionInfo = ({ open, setOpen }: { open: boolean; setOpen: (open: boolean) => void }) => {
    const { t } = useTranslation();

    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='lg'>
            <DialogTitle>
                <h4>{t('Agent configuration and download URL')}</h4>
            </DialogTitle>
            <DialogContent>
                <ConfigureAcomba waitForConnection={false} />
            </DialogContent>
            <DialogActions>
                <Button
                    variant='contained'
                    size='small'
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    {t('Close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
