import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { useQueryArg } from '../components/hooks/useQueryArg';

const UnvalidatedTokenContext = createContext<{
    unvalidatedToken: string | undefined;
    isLoading: boolean;
    storeLocalToken: (token: string) => void;
}>({
    unvalidatedToken: undefined,
    isLoading: true,
    storeLocalToken: () => {},
});

export function useUnvalidatedToken() {
    return useContext(UnvalidatedTokenContext);
}

export type LocalTokenWithExpirationType = {
    token: string;
    expiration: number;
};

export default function UnvalidatedTokenProvider({ children }: { children: ReactNode }) {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [queryToken, setQueryToken, isQueryTokenLoading] = useQueryArg('token');

    const [localToken, setLocalToken] = useLocalStorage<LocalTokenWithExpirationType | undefined>('token', undefined);
    const [unvalidatedToken, setUnvalidatedToken] = useState<string | undefined>(undefined);

    useEffect(() => {
        console.log('unvalidatedToken', unvalidatedToken);
    }, [unvalidatedToken]);

    useEffect(() => {
        //dont change anything if we are already configured
        if (!isLoading) return;

        //dont change anything if we are still loading the query token
        if (isQueryTokenLoading) return;

        if (queryToken) {
            console.log('queryToken', queryToken);
            //if we have a query token, take it and remove it from the query
            setUnvalidatedToken(queryToken);
            setQueryToken(undefined);
        } else if (localToken && localToken.expiration > Date.now()) {
            //if we have a local token and it is not expired, take it
            setUnvalidatedToken(localToken.token);
        }

        setIsLoading(false);
    }, [queryToken, localToken, isLoading, isQueryTokenLoading]);

    const storeLocalToken = useCallback((token: string) => {
        //dont update the stored token if we already have it
        if (localToken?.token === token) return;

        const expiration = Date.now() + 1000 * 60 * 60 * 24 * 7;

        setLocalToken({ token, expiration });

        setQueryToken(undefined);
    }, []);

    return (
        <UnvalidatedTokenContext.Provider
            value={{
                unvalidatedToken,
                isLoading,
                storeLocalToken,
            }}
        >
            {children}
        </UnvalidatedTokenContext.Provider>
    );
}
